import React, { useEffect } from "react";

const DownloadTheApp = () => {
  const detectAndOpen = () => {
    var userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open("https://play.google.com/store/apps/details?id=com.sportsyte.serieafantasy", "_self");
    } else if (/ipad|iphone/i.test(userAgent)) {
      window.open("https://apps.apple.com/app/seriea-fantasy/id6636546642", "_self");
    } else {
      window.open("/", "_self");
    }
  }

  useEffect(() => {
		detectAndOpen();
	}, []);

  return (
    <>
    </>
  );
};

export default DownloadTheApp;

DownloadTheApp.displayName = "dta";