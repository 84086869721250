import * as types from "redux/constants/roundGames";

import createReducer from "redux/createReducer";

const roundGamesInitialState = {
	roundGamesHashList: {},
	roundGamesRequestLoading: false,
	roundGamesRequestError: {},
	roundGamesResultsHashList: {},
	roundGamesResultsLoadingsHashList: {},
	roundGamesResultsErrorsHashList: {},
	cloasestScheduledGamesData: {},
	cloasestScheduledGamesLoading: false,
	cloasestScheduledGamesErrors: {},
	teamsOpponents: {},
};

const roundGamesReducer = createReducer(roundGamesInitialState)({
	[types.GET_REQUEST_ROUND_GAMES_START]: state => ({
		...state,
		roundGamesRequestLoading: true,
	}),
	[types.GET_REQUEST_ROUND_GAMES_SUCCESS]: (state, { data, meta }) => {
		const { roundGames, teamsOpponents } = data;
		return {
			...state,
			roundGamesHashList: { ...state.roundGamesHashList, [meta.roundId]: roundGames },
			teamsOpponents: { ...state.teamsOpponents, [meta.roundId]: teamsOpponents },	
			meta,
			roundGamesRequestLoading: false,
		};},
	[types.CLEAR_ROUND_GAMES_HASH_LIST]: (state) => {
		let liveRoundId = "";
		const roundGamesHashListNewValue = JSON.parse(JSON.stringify(state.roundGamesHashList));
		const teamsOpponentsNewValue = JSON.parse(JSON.stringify(state.teamsOpponents));
		Object.keys(roundGamesHashListNewValue).forEach((val) => {
			if (roundGamesHashListNewValue[val]?.[0]?.football_round.status === "live") {
				liveRoundId = val;
			}
		});
		delete roundGamesHashListNewValue[liveRoundId];
		delete teamsOpponentsNewValue[liveRoundId];

		return {
			...state,
			roundGamesHashList: roundGamesHashListNewValue,
			teamsOpponents: teamsOpponentsNewValue,
		};},
	[types.GET_REQUEST_ROUND_GAMES_ERROR]: (state, { payload }) => ({
		...state,
		roundGamesRequestError: payload,
		roundGamesRequestLoading: false,
	}),
	[types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_START]: (state, { payload }) => {
		const { roundGamesResultsLoadingsHashList, roundGamesResultsErrorsHashList } = state;
		const newRoundGamesResultsLoadingsHashList = { ...roundGamesResultsLoadingsHashList, [payload]: true };
		const newRoundGamesResultsErrorsHashList = { ...roundGamesResultsErrorsHashList, [payload]: false };
		return {
			...state,
			roundGamesResultsLoadingsHashList: newRoundGamesResultsLoadingsHashList,
			roundGamesResultsErrorsHashList: newRoundGamesResultsErrorsHashList,
		};
	},
	[types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_SUCCESS]: (state, { payload }) => {
		const { data, forGameID } = payload;
		const { roundGamesResultsLoadingsHashList, roundGamesResultsErrorsHashList, roundGamesResultsHashList } = state;
		const newRoundGamesResultsLoadingsHashList = { ...roundGamesResultsLoadingsHashList, [forGameID]: false };
		const newRoundGamesResultsErrorsHashList = { ...roundGamesResultsErrorsHashList, [forGameID]: false };
		const newRoundGamesResultsHashList = {
			...roundGamesResultsHashList,
			[forGameID]: { ...roundGamesResultsHashList[forGameID], ...data } };
		return {
			...state,
			roundGamesResultsLoadingsHashList: newRoundGamesResultsLoadingsHashList,
			roundGamesResultsErrorsHashList: newRoundGamesResultsErrorsHashList,
			roundGamesResultsHashList: newRoundGamesResultsHashList,
		};
	},
	[types.GET_REQUEST_GAME_ROUND_FOOTBALL_PLAYERS_POINTS_ERROR]: (state, { payload }) => {
		const { data, forGameID } = payload;
		const { roundGamesResultsLoadingsHashList, roundGamesResultsErrorsHashList } = state;
		const newRoundGamesResultsLoadingsHashList = { ...roundGamesResultsLoadingsHashList, [forGameID]: false };
		const newRoundGamesResultsErrorsHashList = { ...roundGamesResultsErrorsHashList, [forGameID]: data };
		return {
			...state,
			roundGamesResultsLoadingsHashList: newRoundGamesResultsLoadingsHashList,
			roundGamesResultsErrorsHashList: newRoundGamesResultsErrorsHashList,
		};
	},


	[types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_START]: (state) => {
		return {
			...state,
			cloasestScheduledGamesLoading: true,
		};
	},
	[types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			cloasestScheduledGamesLoading: false,
			cloasestScheduledGamesData: payload,
		};
	},
	[types.GET_REQUEST_CLOSEST_SCHEDULED_ROUND_GAMES_ERROR]: (state, { payload }) => {
		return {
			...state,
			cloasestScheduledGamesLoading: false,
			cloasestScheduledGamesErrors: payload,
		};
	},
});

export default roundGamesReducer;