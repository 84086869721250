import React, { useEffect } from "react";
import {
	Row, Col, Spinner
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import Info from "static/assets/images/info-blue.svg";
import { useTranslation } from "react-i18next";

import { roundGamePropType } from "utils/propTypes";

import { actionGetRequestGameFootballPlayersPoints } from "redux/actions/roundGames";
import { actionSetPlayerInfoModalID } from "redux/actions/playersList";

import { ACTION_TYPES_ORDERED_GAME_INFO } from "helpers/constants/common";

import { Team, Result } from "modules/common/GameItem";
import ModalWindow from "modules/common/ModalWindow";


const PlayerStatColumn = ({ data, isRight, actionSetPlayerInfoModalID }) => {
	const structuredData = {};
	data.forEach((item) => {
		structuredData[item.playerName] = structuredData[item.playerName] ? [
			...structuredData[item.playerName],
			item.points,
		] : [item.points];
	});
	const sortedData = data.sort((a, b) => {
		const compareValueA = a.count;
		const compareValueB = b.count;
		if (compareValueA < compareValueB) {
			return 1;
		}
		if (compareValueA > compareValueB) {
			return -1;
		}
		return 0;
	});

	return (
		<Col className='p-0' xs={6}>
			{sortedData.map((playerStats, index) => (
				<div key={`${playerStats.playerName}-${index}`} className='game-item__player-stats-wrapper'>
					<span className={`game-item__player-stats-inner ${isRight ? "is-right" : ""}`}>
						<span>{playerStats.playerName}</span>
						<span>&nbsp;|&nbsp;{playerStats.count}&nbsp;</span>
						<span>({playerStats.points})</span>
						<img
							onClick={() => { actionSetPlayerInfoModalID(playerStats.playerId); }}
							className='ml-1'
							src={Info}
							alt="open player details"
						/>
					</span>
				</div>
			))}
		</Col>
	);
};

const PlayersStatsBlock = ({ teamLeftID, teamRightID, data, title, key, actionSetPlayerInfoModalID }) => (
	<Row key={key} className='w-100 p-0 game-item__player-stats-block'>
		<Col xs={12} className='game-item__yellow-title'>
			{title}
		</Col>
		<PlayerStatColumn data={data[teamLeftID]} actionSetPlayerInfoModalID={actionSetPlayerInfoModalID} />
		<PlayerStatColumn data={data[teamRightID]} actionSetPlayerInfoModalID={actionSetPlayerInfoModalID} isRight />
	</Row>
);

const GameInfoModal = ({
	switchModal,
	openModalState,
	roundGame = {},
	roundGamesResultsHashList,
	roundGamesResultsLoadingsHashList,
	actionGetRequestGameFootballPlayersPoints,
	actionSetPlayerInfoModalID,
}) => {
	const { t } = useTranslation("game_info_modal");
	const { id: gameID, status } = roundGame;
	const isScheduledGame = status === "scheduled";
	const isCanceledGame = status === "cancelled";
	const isForfeitedGame = status === "technical";
	const isMatchDataIsBeingAdded = status === "finished" && (roundGamesResultsHashList[gameID] && !Object.keys(roundGamesResultsHashList[gameID]).length);

	useEffect(() => {
		if (openModalState && gameID && !isScheduledGame) {
			actionGetRequestGameFootballPlayersPoints(gameID);
		}
	}, [openModalState]);
	return (
		<ModalWindow contentClassName="game-info-modal" modalClassName="game-info-modal-wrapper" isOpen={openModalState} toggle={switchModal}>
			<Row className='w-100 justify-content-center'>
				<Team fullwidth {...roundGame.team_1} />
				<Result
					score1={isCanceledGame ? "-" : roundGame && roundGame.team_1_score}
					score2={isCanceledGame ? "-" : roundGame && roundGame.team_2_score}
					status={roundGame && roundGame.status}
					matchLocaleTime={roundGame.matchLocaleTime}
				/>
				<Team fullwidth {...roundGame.team_2} />
			</Row>
			{roundGame && roundGame.football_round &&
				<Row className='w-100 justify-content-center'>
					{isScheduledGame &&
						<h4 className="mt-5 font-h4">
							{t("Match is scheduled")}
						</h4>
					}
					{isCanceledGame &&
						<h4 className="mt-5 font-h4">
							{t("Match is cancelled")}
						</h4>
					}
					{isForfeitedGame &&
						<h4 className="mt-5 font-h4">
							{t("Match is forfeited")}
						</h4>
					}
					{isMatchDataIsBeingAdded &&
						<h4 className="mt-5 font-h4">
							{t("Match data is being added")}
						</h4>
					}
				</Row>
			}
			{gameID && !roundGamesResultsLoadingsHashList[gameID] && roundGamesResultsHashList[gameID] && !!Object.keys(roundGamesResultsHashList[gameID]).length &&
				<Scrollbars
					renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
					renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
					style={{ height: "60vh" }}
					className="game-item__scrollbar">
					{Object.keys(ACTION_TYPES_ORDERED_GAME_INFO).map(key => {
						if (roundGamesResultsHashList[gameID][key]) {
							return <PlayersStatsBlock
								key={`${roundGame.team_1.id}-${roundGame.team_2.id}-${gameID}-${key}`}
								teamLeftID={roundGame.team_1.id}
								teamRightID={roundGame.team_2.id}
								data={roundGamesResultsHashList[gameID][key]}
								title={ACTION_TYPES_ORDERED_GAME_INFO[key]}
								actionSetPlayerInfoModalID={actionSetPlayerInfoModalID}
							/>;
						}
						return null;
					})}
				</Scrollbars>
			}
			{gameID && roundGamesResultsLoadingsHashList[gameID] &&
				<Row className='w-100 p-4 d-flex align-items-center justify-content-center'>
					<Spinner />
				</Row>
			}
		</ModalWindow>
	);
};

const mapStateToProps = ({ roundGamesReducer }) => {
	const { roundGamesResultsHashList, roundGamesResultsLoadingsHashList } = roundGamesReducer;
	return {
		roundGamesResultsHashList,
		roundGamesResultsLoadingsHashList
	};

};

export default connect(
	mapStateToProps,
	{
		actionGetRequestGameFootballPlayersPoints,
		actionSetPlayerInfoModalID,
	}
)(GameInfoModal);

PlayerStatColumn.propTypes = {
	data: PropTypes.array,
	isRight: PropTypes.bool,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
};

PlayerStatColumn.defaultProps = {
	data: [],
	isRight: false,
};

GameInfoModal.propTypes = {
	className: PropTypes.string,
	switchModal: PropTypes.func.isRequired,
	openModalState: PropTypes.bool.isRequired,
	roundGamesResultsHashList: PropTypes.object.isRequired,
	roundGamesResultsLoadingsHashList: PropTypes.object.isRequired,
	roundGame: PropTypes.shape(roundGamePropType.resultGame).isRequired,
	actionGetRequestGameFootballPlayersPoints: PropTypes.func.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired
};

GameInfoModal.defaultProps = {
	className: "",
};

PlayersStatsBlock.propTypes = {
	teamLeftID: PropTypes.number,
	teamRightID: PropTypes.number,
	data: PropTypes.object,
	title: PropTypes.string.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
	key: PropTypes.string,
};

PlayersStatsBlock.defaultProps = {
	teamLeftID: 0,
	teamRightID: 0,
	data: {},
	key: ""
};

PlayersStatsBlock.displayName = "psb";
GameInfoModal.displayName = "gim";
PlayerStatColumn.displayName = "ptc";