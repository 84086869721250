import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import _ from "lodash";
import propTypes from "prop-types";

import { LIVE, STATUS } from "routes/backendFilters";

import { actionGetRequestLeagueSeasons } from "redux/actions/leagueSeasons";

import Counters from "modules/common/Counters";
import DreamTeam from "modules/home/containers/DreamTeam";
import Screen1 from "modules/home/components/MainScreen";
import SignUpBlock from "modules/home/components/SignUpBlock";
import NewsBlock from "modules/news/containers/NewsBlock";
import RoundResultsList from "modules/home/containers/RoundResultsList";
import Title from "modules/common/Title";
import AnnouncementBanner from "modules/common/AnnouncementBanner";


const Home = ({ actionGetRequestLeagueSeasons,seasonsListRequestError }) => {
	useEffect(() => {
		if (!_.isEmpty(seasonsListRequestError)) {
			actionGetRequestLeagueSeasons(STATUS, LIVE);
		}
	}, []);
	return (
		<div className="home-container">
			<Container className="global-container">
			    <Screen1 />
                <Row>
                    <Title  translation={{
                        ns: "news_and_numbers",
                        key: "news_and_numbers"
                    }} />
                </Row>
			    <Counters />
			    <AnnouncementBanner />
                <NewsBlock />
            </Container>
			<DreamTeam />
			<RoundResultsList />
			<SignUpBlock />
		</div>
	);};

Home.propTypes = {
	seasonsListRequestError: propTypes.object.isRequired,
	actionGetRequestLeagueSeasons: propTypes.func.isRequired,
};


const mapStateToProps = ({ leagueSeasonsReducer }) => {
	const { seasonsListRequestError } = leagueSeasonsReducer;
	return {
		seasonsListRequestError,
	};
};

export default connect(
	mapStateToProps,
	{ actionGetRequestLeagueSeasons,  }
)(Home);

Home.displayName = "home";