import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Row, Col, Spinner } from "reactstrap";
import KeyIcon from "static/assets/images/key.svg";
import SettingsIcon from "static/assets/images/settings.svg";
import LeaveIcon from "static/assets/images/leave.svg";
import CalendarIcon from "static/assets/images/calendar.svg";
import clockIcon from "static/assets/images/clock.svg";
import moment from "moment";

import { leaguesPropType } from "utils/propTypes/leaguesSeasons";

import backendApiUrls from "routes/backendUrls";

import { actionSetLeagueType, actionSetLeagueSubtype } from "redux/actions/leaguesType";
import { actionSetSelectedRoundLeague, actionHydrateSelectedRoundLeagueFromStorage } from "redux/actions/leaguesSelectedRoundLeague";
import { actionGetRequestFinishedRoundsSortedFromNew } from "redux/actions/seasonRounds";
import { actionGetRequestFindOwnLeagues, actionClearFindOwnLeaguesError } from "redux/actions/leaguesFindOwn";
import { actionGetRequestCountryInfo } from "redux/actions/leaguesCountry";
import {
	actionResetLeagueMembersPaginationList
} from "redux/actions/leaguesPagination";
import {
	actionGetRequestPrivateLeagueFindOwn,
	actionClearPrivateLeagueInfoError,
	actionGetRequestPrivateLeaguesInfo,
	setLeavePrivateLeagueModalState,
	setEditPrivateLeagueModalState,
} from "redux/actions/leaguesPrivate";
import { getFinishedAndLiveQuadLeagues, getFinishedAndLiveRoundsReverse } from "redux/selectors";
import { actionGetRequestRoundLeagueInfo } from "redux/actions/leaguesRound";
import { actionGetRequestSeasonLeagueInfo } from "redux/actions/leaguesSeason";
import { actionGetQuadLeagueInfo, actionGetRequestQuadLeagues } from "redux/actions/leaguesQuad";

import { LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE } from "helpers/constants/common";
import { numberWithSpaces } from "helpers/common";

import Title from "modules/common/Title";
import Select from "modules/common/Select";
import ErrorComponent from "modules/common/ErrorComponent";

import MembersList from "./MembersList";
import PaginationWrapper from "./PaginationWrapper";
import CopyIcon from "./CopyIcon";
import useQuery from "helpers/hooks/useQuery";
import history from "helpers/history";
import { useTranslation } from "react-i18next";

const LeaguesRanking = ({
	actionGetRequestRoundLeagueInfo,
	actionGetRequestSeasonLeagueInfo,
	totalMembers,
	membersList,
	selectorLoading,
	ownData,
	leagueName,
	requestLoading,
	privateLeaguesInfoHashList,
	lastFinishedRoundId,
	activeSeasonId,
	userCountryCode,
	actionSetLeagueType,
	actionSetSelectedRoundLeague,
	actionHydrateSelectedRoundLeagueFromStorage,
	actionSetLeagueSubtype,
	actionGetRequestFinishedRoundsSortedFromNew,
	actionGetRequestFindOwnLeagues,
	actionClearFindOwnLeaguesError,
	actionGetQuadLeagueInfo,
	actionGetRequestQuadLeagues,
	actionGetRequestPrivateLeaguesInfo,
	actionResetLeagueMembersPaginationList,
	actionGetRequestPrivateLeagueFindOwn,
	finishedAndLiveQueadLeagues,
	actionGetRequestCountryInfo,
	finishedAndLiveRounds,
	actionClearPrivateLeagueInfoError,
	roundFindOwnRequestError,
	quadFindOwnRequestError,
	countryFindOwnRequestError,
	privateFindOwnRequestError,
	seasonFindOwnRequestError,
	findOwnLeaguesRequestLoading,
	countryName,
	activeCountryLeagueId,
	activeSeasonLeagueId,
	activeQuadLeagueId,
	setLeavePrivateLeagueModalState,
	setEditPrivateLeagueModalState,
	activeRoundLeagueId,
	paginationRequestLoading,
	currentLeaguesSubtypeOrID,
	privateLeagueId,
	currentLeaguesType,
	isPrivateLeague,
	selectedRoundLeagues,
}) => {
	const { t } = useTranslation("leagues_ranking");
	const [currentSelectItem, setCurrentSelectItem] = useState({});
	const [leagueNameLocal, setLeagueNameLocal] = useState(leagueName);
	const [leagueWasChanged, setLeagueWasChanged] = useState(true);
	const lastUpdateTime = ownData?.last_update_time;

	let selectData = [];
	switch (currentLeaguesSubtypeOrID) {
		case "round":
			selectData = finishedAndLiveRounds;
			break;
		case "quad":
			selectData = finishedAndLiveQueadLeagues;
			break;
		case "season":
		case "country":
			selectData = [ownData];
			break;
		default:
			selectData = [];
	}

	useEffect(() => {
		actionClearFindOwnLeaguesError();
	}, []);

	useEffect(() => {
		if (countryName && currentLeaguesSubtypeOrID === "country") {
			setLeagueNameLocal(countryName);
		}
	}, [countryName]);


	useEffect(() => {
		if (currentLeaguesSubtypeOrID === "round"
			|| currentLeaguesSubtypeOrID === "quad"
			|| currentLeaguesSubtypeOrID === "season"
		) {
			setLeagueNameLocal(currentLeaguesSubtypeOrID);
		}
	}, [currentLeaguesSubtypeOrID]);

	useEffect(() => {
		actionSetLeagueType(currentLeaguesType);
		actionSetLeagueSubtype(currentLeaguesSubtypeOrID);
		actionResetLeagueMembersPaginationList();
		actionHydrateSelectedRoundLeagueFromStorage()
		!lastFinishedRoundId
			&& (currentLeaguesSubtypeOrID === "round" || isPrivateLeague)
			&& actionGetRequestFinishedRoundsSortedFromNew({ status: "finished", ordering: "-created" });
	}, []);


	useEffect(() => {
		if (selectData?.length
			&& (currentLeaguesSubtypeOrID === "round"
				|| currentLeaguesSubtypeOrID === "quad"
				|| currentLeaguesSubtypeOrID === "season"
				|| currentLeaguesSubtypeOrID === "country"
			)
		) {
			if (currentLeaguesSubtypeOrID === "round" && selectedRoundLeagues.round) {
				if (JSON.stringify(selectedRoundLeagues.round) !== JSON.stringify(currentSelectItem)) {
					setCurrentSelectItem(selectedRoundLeagues.round);
				}
				return
			}
			if (currentLeaguesSubtypeOrID === "quad" && selectedRoundLeagues.quad) {
				if (JSON.stringify(selectedRoundLeagues.quad) !== JSON.stringify(currentSelectItem)) {
					setCurrentSelectItem(selectedRoundLeagues.quad);
				}
				return
			}

			if (JSON.stringify(selectData[0]) !== JSON.stringify(currentSelectItem)) {
				setCurrentSelectItem(selectData[0]);
			}
		}
	}, [selectData]);

	useEffect(() => {
		if (isPrivateLeague && privateLeaguesInfoHashList[currentLeaguesSubtypeOrID]) {
			setLeagueNameLocal(privateLeaguesInfoHashList[currentLeaguesSubtypeOrID].name);
		}
	}, [privateLeaguesInfoHashList]);

	const handleSelect = value => {
		history.push({
			pathname: history.location.pathname,
			search: ""
		});
		setLeagueWasChanged(true);
		setCurrentSelectItem(value);
		actionSetSelectedRoundLeague({
			type: currentLeaguesSubtypeOrID,
			value,
		});
	};

	let valueObjectKey;
	let labelObjectKey;
	const selectPlaceholder = selectorLoading ? t("Loading...") :  t("select", { item: currentLeaguesSubtypeOrID});

	switch (currentLeaguesSubtypeOrID) {
		case "round":
			valueObjectKey = "id";
			labelObjectKey = "number";
			break;
		case "quad":
			valueObjectKey = "id";
			labelObjectKey = "name";
			break;
	}

	const [ownDataLoading, setOwnDataLoading] = useState(true);

	const pageNumber = +useQuery().get("page");

	useEffect(() => {
		if (pageNumber !== ownData?.page
			&& ownData?.id
			&& !requestLoading
			&& !ownDataLoading
			&& !leagueWasChanged
			&& !pageNumber
		) {
			history.push({
				pathname: history.location.pathname,
				search: `?page=${ownData?.page}${privateLeagueId ? '&league_id=' + privateLeagueId : ''}`,
			});
		}
	}, [ownData.page, ownData.id, ownDataLoading]);

	useEffect(() => {
		if (currentSelectItem.id && (!lastUpdateTime || leagueWasChanged) && currentLeaguesSubtypeOrID === "round") {
			actionGetRequestRoundLeagueInfo(currentSelectItem.id);
		}
	}, [currentSelectItem.id]);

	useEffect(() => {
		activeSeasonId && !activeSeasonLeagueId && currentLeaguesSubtypeOrID === "season" && actionGetRequestSeasonLeagueInfo(activeSeasonId);
	}, [activeSeasonId]);

	useEffect(() => {
		!activeQuadLeagueId && activeSeasonId && currentLeaguesSubtypeOrID === "quad" && actionGetRequestQuadLeagues(activeSeasonId);
	}, [activeSeasonId]);

	useEffect(() => {
		(!lastUpdateTime || leagueWasChanged) && currentSelectItem.id && currentLeaguesSubtypeOrID === "quad" && actionGetQuadLeagueInfo(currentSelectItem.id);
	}, [currentSelectItem.id]);

	useEffect(() => {
		if (!activeCountryLeagueId && currentLeaguesSubtypeOrID === "country" && userCountryCode && activeSeasonId) {
			actionGetRequestCountryInfo(activeSeasonId, userCountryCode);
		}
	}, [userCountryCode, activeSeasonId]);

	useEffect(() => {
		if (currentLeaguesSubtypeOrID === "round" && activeRoundLeagueId) {
			if (leagueWasChanged) {
				actionGetRequestFindOwnLeagues(backendApiUrls.leagueRoundLeagueOwn, activeRoundLeagueId, LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE, "round");
				setLeagueWasChanged(false);
			}
			setOwnDataLoading(false);
		}
	}, [activeRoundLeagueId]);

	useEffect(() => {
		if (currentLeaguesSubtypeOrID === "quad" && ownData.id) {
			if (leagueWasChanged) {
				actionGetRequestFindOwnLeagues(backendApiUrls.leagueQuadLeagueOwn, ownData.id, LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE, "quad");
				setLeagueWasChanged(false);
			}
			setOwnDataLoading(false);
		}
	}, [ownData.id]);

	useEffect(() => {
		if (currentLeaguesSubtypeOrID === "country" && activeCountryLeagueId) {
			actionGetRequestFindOwnLeagues(backendApiUrls.leagueCountryLeagueOwn, activeCountryLeagueId, LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE, "country");
			setOwnDataLoading(false);
		}
	}, [activeCountryLeagueId]);

	useEffect(() => {
		if (currentLeaguesSubtypeOrID === "season" && activeSeasonLeagueId) {
			actionGetRequestFindOwnLeagues(backendApiUrls.leagueSeasonLeagueOwn, activeSeasonLeagueId, LEAGUES_MEMBERS_PAGINATION_PAGE_SIZE, "season");
			setOwnDataLoading(false);
		}
	}, [activeSeasonLeagueId]);

	useEffect(() => {
		if (isPrivateLeague && currentLeaguesSubtypeOrID) {
			if (!ownData?.page) {
				actionGetRequestPrivateLeagueFindOwn({ leagueID: currentLeaguesSubtypeOrID })
					.then(() => setOwnDataLoading(false));
			} else {
				setOwnDataLoading(false);
			}
		}
	}, [currentLeaguesSubtypeOrID, currentLeaguesType]);

	const joinCode = privateLeaguesInfoHashList[currentLeaguesSubtypeOrID]?.join_code;
	const roundFromCount = privateLeaguesInfoHashList[currentLeaguesSubtypeOrID]?.starts_from_round?.number || 1;

	useEffect(() => {
		if (joinCode === undefined && isPrivateLeague && activeSeasonId) {
			actionGetRequestPrivateLeaguesInfo(activeSeasonId);
		}
	}, [activeSeasonId, isPrivateLeague]);

	const showSelect = currentLeaguesSubtypeOrID === "round" || currentLeaguesSubtypeOrID === "quad";

	const handleClearLeaguesRequestErrors = () => {
		actionClearPrivateLeagueInfoError({ forLeagueID: currentLeaguesSubtypeOrID });
		actionClearFindOwnLeaguesError();
	};
	const isDataReady =
		!ownDataLoading &&
		!requestLoading &&
		!selectorLoading &&
		!findOwnLeaguesRequestLoading &&
		(((totalMembers || totalMembers === 0) && leagueNameLocal) || !isPrivateLeague) &&
		!paginationRequestLoading;

	const errors = {
		roundFindOwnRequestError,
		quadFindOwnRequestError,
		countryFindOwnRequestError,
		privateFindOwnRequestError,
		seasonFindOwnRequestError,
	}

	const translation =  {
		ns: "leagues_ranking",
		key: "title_" + (isPrivateLeague ? "private" : currentLeaguesSubtypeOrID),
		values: {
			leagueName: leagueNameLocal
		}
	}

	return (
		<div className="leagues-ranking">
			<Container className={`leagues-ranking__container global-container ${!isDataReady && "d-none"}`}>
				{isPrivateLeague ?
					<div>
						<Title translation={translation} className="leagues-ranking__title"  />
						<p className="leagues-ranking__league-name">{leagueNameLocal}</p>
					</div>
					:
					leagueNameLocal !== "country" && <Title translation={translation} className="leagues-ranking__title" />}
				{!requestLoading && <p className="leagues-ranking__total-members">{t('members', { count: numberWithSpaces(totalMembers)})}</p>}
				<Row className="leagues-ranking__errors-wrapper mx-0 justify-content-center">
					{errors?.[`${currentLeaguesSubtypeOrID}FindOwnRequestError`]?.parsedTextErrors?.map((error) => (
						<ErrorComponent
							handleDelete={handleClearLeaguesRequestErrors}
							text={error}
						/>
					))}
				</Row>
				{(isPrivateLeague || showSelect) &&
					<Row className="leagues-ranking__row justify-content-center">
						{isPrivateLeague &&
							<Col md={showSelect ? 6 : 12} className="order-md-2 d-flex flex-column align-items-center">
								{joinCode && <p className="leagues-ranking__code">
									{t('League Code')}&nbsp;
									<img
										className='mb-1'
										src={KeyIcon} alt=""
									/>&nbsp;
									<span className="font-weight-bold">{joinCode}</span>
									&nbsp;
									<CopyIcon code={joinCode} />
								</p>}

								<img
									onClick={() => {
										if (joinCode) {
											setEditPrivateLeagueModalState(privateLeaguesInfoHashList[currentLeaguesSubtypeOrID]);
										} else {
											setLeavePrivateLeagueModalState(privateLeaguesInfoHashList[currentLeaguesSubtypeOrID]);
										}
									}}
									className='settings-icon'
									src={joinCode ? SettingsIcon : LeaveIcon} alt=""
								/>
							</Col>
						}
						{showSelect &&
							<Col md={isPrivateLeague && joinCode ? 6 : 12}>
								<Select
									placeholder={selectPlaceholder}
									handleChange={handleSelect}
									defaultOption={currentSelectItem[valueObjectKey]}
									values={selectData || []}
									valueObjectKey={valueObjectKey}
									labelObjectKey={labelObjectKey}
									isLoading={selectorLoading}
									isSearchable={!(currentLeaguesSubtypeOrID === "round" || currentLeaguesSubtypeOrID === "quad")}
								/>
							</Col>
						}
					</Row>
				}
				{totalMembers === 0 && isDataReady &&
					<div className='w-100 text-center font-m2 source-sans-pro'>
						{t('All the members will appear in this league once the Round starts')}
					</div>}
				{totalMembers > 0 && <Row className="justify-content-between mb-2">
					<Col>
						{lastUpdateTime && <div className="font-l2 d-flex align-items-center">
							{t('Update')}&nbsp;
							<img src={clockIcon} alt="" />
							&nbsp;
							<span className="font-weight-bold">
								{moment(lastUpdateTime).format("DD.MM.YY @ HH:mm")}
							</span>
						</div>
						}
					</Col>
					{isPrivateLeague && <Col className="d-flex justify-content-end align-items-center count-from-info font-l2">
						<p className="mb-0">
							<span>{t('Count from')}</span>
						</p>
						&nbsp;
						<img src={CalendarIcon} alt="" />
						&nbsp;
						<span className="font-weight-bold">
							{t('round', { number:roundFromCount })}
						</span>
					</Col>}
				</Row>}
				{totalMembers > 0 && <MembersList membersList={membersList} />}
				{totalMembers > 0 && requestLoading && <div className='mb-3 w-100 d-flex justify-content-center'><Spinner /></div>}
				{totalMembers > 0 && findOwnLeaguesRequestLoading && <div className='mb-3 w-100 d-flex justify-content-center'><Spinner /></div>}
				<PaginationWrapper
					userCountryCode={userCountryCode}
					className="leagues-ranking__pagination"
					selectedItem={currentSelectItem}
					currentLeaguesSubtypeOrID={currentLeaguesSubtypeOrID}
					ownPage={ownData?.id ? ownData?.page || 0 : ownData?.page}
				/>
			</Container>
			<Container className={`leagues-ranking__container ${isDataReady && "d-none"}`}>
				{!isDataReady && <div className='w-100 d-flex justify-content-center'><Spinner /></div>}
			</Container>
		</div>
	);
};

LeaguesRanking.propTypes = {
	currentLeaguesSubtypeOrID: PropTypes.string.isRequired,
	privateLeagueId: PropTypes.number.isRequired,
	currentLeaguesType: PropTypes.oneOf(["private", "common"]),
	isPrivateLeague: PropTypes.bool.isRequired,
	activeSeasonId: PropTypes.number,
	membersList: PropTypes.arrayOf(leaguesPropType.leagues).isRequired,
	totalMembers: PropTypes.number.isRequired,
	paginationTotalMembersCount: PropTypes.number.isRequired,
	selectData: PropTypes.array,
	selectorLoading: PropTypes.bool,
	requestError: PropTypes.bool,
	ownData: PropTypes.oneOfType([
		PropTypes.shape({
			page: PropTypes.number,
			rank: PropTypes.number,
			total_points: PropTypes.number,
		}),
		PropTypes.object,
	]),
	privateLeaguesError: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({
			parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
		})),
		PropTypes.bool,
	]),
	roundFindOwnRequestError: PropTypes.shape({
		parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
	}),
	quadFindOwnRequestError: PropTypes.shape({
		parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
	}),
	countryFindOwnRequestError: PropTypes.shape({
		parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
	}),
	privateFindOwnRequestError: PropTypes.shape({
		parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
	}),
	seasonFindOwnRequestError: PropTypes.shape({
		parsedTextErrors: PropTypes.arrayOf(PropTypes.string),
	}),
	leagueName: PropTypes.string.isRequired,
	requestLoading: PropTypes.bool.isRequired,
	findOwnLeaguesRequestLoading: PropTypes.bool.isRequired,
	privateLeaguesInfoHashList: PropTypes.object.isRequired,
	selectedRoundLeagues: PropTypes.object.isRequired,
	lastFinishedRoundId: PropTypes.number.isRequired,
	userCountryCode: PropTypes.string,
	actionSetLeagueType: PropTypes.func.isRequired,
	actionSetSelectedRoundLeague: PropTypes.func.isRequired,
	actionHydrateSelectedRoundLeagueFromStorage: PropTypes.func.isRequired,
	actionSetLeagueSubtype: PropTypes.func.isRequired,
	actionGetRequestFindOwnLeagues: PropTypes.func.isRequired,
	actionGetRequestFinishedRoundsSortedFromNew: PropTypes.func.isRequired,
	actionResetLeagueMembersPaginationList: PropTypes.func.isRequired,
	actionGetQuadLeagueInfo: PropTypes.func.isRequired,
	actionGetRequestQuadLeagues: PropTypes.func.isRequired,
	actionGetRequestCountryInfo: PropTypes.func.isRequired,
	actionGetRequestPrivateLeaguesInfo: PropTypes.func.isRequired,
	actionGetRequestPrivateLeagueFindOwn: PropTypes.func.isRequired,
	actionClearPrivateLeagueInfoError: PropTypes.func.isRequired,
	actionGetRequestRoundLeagueInfo: PropTypes.func.isRequired,
	actionGetRequestSeasonLeagueInfo: PropTypes.func.isRequired,
	actionClearFindOwnLeaguesError: PropTypes.func.isRequired,
	finishedAndLiveQueadLeagues: PropTypes.array.isRequired,
	countriesListData: PropTypes.array.isRequired,
	finishedAndLiveRounds: PropTypes.array.isRequired,
	countryName: PropTypes.string.isRequired,
	activeCountryLeagueId: PropTypes.string.isRequired,
	activeRoundLeagueId: PropTypes.string.isRequired,
	activeSeasonLeagueId: PropTypes.string.isRequired,
	activeQuadLeagueId: PropTypes.string.isRequired,
	setLeavePrivateLeagueModalState: PropTypes.func.isRequired,
	setEditPrivateLeagueModalState: PropTypes.func.isRequired,
	paginationRequestLoading: PropTypes.bool.isRequired,
};

LeaguesRanking.defaultProps = {
	activeSeasonId: null,
	totalMembers: 0,
	selectorLoading: false,
	requestError: false,
	userCountryCode: "",
};

const mapStateToProps = state => {
	const { leagueSeasonsReducer, leaguesReducer, seasonRoundsReducer, userTeamsReducer } = state;
	const { seasonsListData, seasonsListRequestLoading } = leagueSeasonsReducer;
	const {
		privateLeaguesInfoHashList,
		privateLeaguesInfoRequestErrorHashList,
		leaguesSubtype,
		leaguesType,
		countriesListData,
		roundFindOwnData,
		roundFindOwnRequestLoading,
		quadFindOwnData,
		quadFindOwnRequestLoading,
		countryFindOwnData,
		countryFindOwnRequestLoading,
		seasonFindOwnData,
		seasonFindOwnRequestLoading,
		findOwnLeaguesRequestError,
		findOwnLeaguesRequestLoading,
		paginationData,
		paginationRequestLoading,
		countriesListRequestLoading,
		countryData,
		roundLeaguesData,
		seasonLeagueInfo,
		quadLeagueInfo,
		roundLeaguesRequestLoading,
		roundFindOwnRequestError,
		quadFindOwnRequestError,
		countryFindOwnRequestError,
		privateFindOwnRequestError,
		seasonFindOwnRequestError,
		selectedRoundLeagues,
	} = leaguesReducer;
	const { name: countryName } = countryData;
	const { finishedRoundsSortedFromNewLoading } = seasonRoundsReducer;
	const { next, previous, results: membersList = [] } = paginationData;
	const { userTeamsData } = userTeamsReducer;
	const { requestError } = findOwnLeaguesRequestError;
	const userCountryCode = userTeamsData[0] && userTeamsData[0].user.country;
	let selectorLoading = false;
	let leagueName = "";
	const finishedAndLiveRounds = getFinishedAndLiveRoundsReverse(state);
	const finishedAndLiveQueadLeagues = getFinishedAndLiveQuadLeagues(state);
	const lastFinishedRoundId = finishedAndLiveRounds.length && finishedAndLiveRounds[0].id;
	const requestLoading = roundLeaguesRequestLoading
		|| finishedRoundsSortedFromNewLoading || seasonsListRequestLoading || countriesListRequestLoading
		|| roundFindOwnRequestLoading || seasonFindOwnRequestLoading || countryFindOwnRequestLoading || quadFindOwnRequestLoading;

	const { pathname } = window.location;
	const currentLeaguesType = pathname.split("/")[2];
	const urlParams = new URLSearchParams(window.location.search);
	const isPrivateLeague = currentLeaguesType === "private";
	const privateLeagueId = +urlParams.get("league_id")
	const currentLeaguesSubtypeOrID = isPrivateLeague ? privateLeagueId : pathname.split("/")[3];

	let ownData = privateLeaguesInfoHashList?.[currentLeaguesSubtypeOrID] || {};
	switch (currentLeaguesSubtypeOrID) {
		case "round":
			ownData = { ...roundLeaguesData, ...roundFindOwnData };
			break;
		case "quad":
			ownData = { ...quadLeagueInfo, ...quadFindOwnData };
			break;
		case "country":
			ownData = { ...countryData, ...countryFindOwnData };
			break;
		case "season":
			ownData = { ...seasonLeagueInfo, ...seasonFindOwnData };
			break;
	}

	return {
		selectedRoundLeagues,
		currentLeaguesType,
		isPrivateLeague,
		privateLeagueId,
		currentLeaguesSubtypeOrID,
		quadLeagueId: quadLeagueInfo.id,
		countryName,
		finishedAndLiveQueadLeagues,
		countriesListData,
		finishedAndLiveRounds,
		activeSeasonId: seasonsListData[0] && seasonsListData[0].id,
		totalMembers: +paginationData.count || 0,
		membersList,
		selectorLoading,
		leaguesType,
		leaguesSubtype,
		ownData,
		previous,
		next,
		requestError,
		userCountryCode,
		lastFinishedRoundId,
		privateLeaguesInfoHashList,
		requestLoading,
		leagueName,
		privateLeaguesError: privateLeaguesInfoRequestErrorHashList[leaguesSubtype],
		roundFindOwnRequestError,
		quadFindOwnRequestError,
		countryFindOwnRequestError,
		privateFindOwnRequestError,
		seasonFindOwnRequestError,
		findOwnLeaguesRequestLoading,
		activeSeasonLeagueId: seasonLeagueInfo?.id,
		activeCountryLeagueId: countryData?.id,
		activeRoundLeagueId: roundLeaguesData?.id,
		activeQuadLeagueId: finishedAndLiveQueadLeagues?.[0]?.id,
		paginationRequestLoading,
	};
};

export default connect(
	mapStateToProps,
	{
		actionSetLeagueType,
		actionSetSelectedRoundLeague,
		actionHydrateSelectedRoundLeagueFromStorage,
		actionSetLeagueSubtype,
		actionGetRequestFindOwnLeagues,
		actionGetRequestFinishedRoundsSortedFromNew,
		actionGetQuadLeagueInfo,
		actionGetRequestQuadLeagues,
		actionGetRequestCountryInfo,
		actionGetRequestPrivateLeaguesInfo,
		actionResetLeagueMembersPaginationList,
		actionGetRequestPrivateLeagueFindOwn,
		actionClearPrivateLeagueInfoError,
		actionClearFindOwnLeaguesError,
		actionGetRequestRoundLeagueInfo,
		actionGetRequestSeasonLeagueInfo,
		setLeavePrivateLeagueModalState,
		setEditPrivateLeagueModalState,
	},
)(LeaguesRanking);

LeaguesRanking.displayName = "lr";