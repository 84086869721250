import React from "react";
import { Container } from "reactstrap";

import Title from "modules/common/Title";
import Tabs from "modules/prizes/components/Tabs"

const Prizes = () => {
	return (
        <Container className="prizes global-container">
            <Title translation={{
                ns: "prizes",
                key: "Prizes",
            }}  />
            <Tabs />
        </Container>
	);
};

export default Prizes;

Prizes.displayName = "prz";