import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Input, Modal } from "reactstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import scrollToComponent from "react-scroll-to-component";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType,
	leaguesSeasonsPropType
} from "utils/propTypes";
import { parsedFieldTeamType, userTeamPropType } from "utils/propTypes/footballTeam";

import frontendUrls from "routes/frontendUrls";

import { actionTeamsListRequest } from "redux/actions/teamsList";
import {
	createEditUserTeamPlayersRequestStartAction,
	actionCleanUserTeamError,
	createEditUserTeamRequestErrorAction,
	actionHeadCheckLiveSeasonUserTeamRequest,
} from "redux/actions/userTeams";
import {
	actionInitTeam,
	actionResetTeam,
	actionPostRequestRandomTeam,
	actionSetCurrentTeamName,
} from "redux/actions/footballField";
import {
	getFieldPlayersTotalLength,
	getFieldPlayersTotalPrice,
	getSortedRounds,
	getNextScheduledRound,
	getUserTeamIdsPlayersHashList,
} from "redux/selectors";

import { validateUserTeam } from "helpers/validateUserTeam";
import useQuery from "helpers/hooks/useQuery";
import {
	TEAM_NAME_IS_REQUIRED,
	TEAM_NAME_BAD_WORDS,
	TEAM_NAME_ONLY_LATIN_OR_DIGITS,
	TEAM_NAME_SHOULD_BE_LESS_31,
} from "helpers/constants/errors";

import FootballFieldContainer from "modules/footballField/containers/FootballFieldContainer";
import MainButton from "modules/common/MainButton";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorMessage from "modules/common/ErrorMessage";
import PlayersListHeadStats from "modules/editTeam/components/PlayersListHeadStats";
import PlayersList from "modules/editTeam/components/PlayersList";
import ErrorComponent from "modules/common/ErrorComponent";
import ProgressNextSeason from "modules/registration/components/ProgressNextSeason";
import PersonalDetailsShort from "../registration/containers/PersonalDetailsShort";

const NextSeasonTeamContainer = ({ leagueSeasons, userTeamsEditCreateRequestErrors, fieldTeamLength, actionCleanUserTeamError, currentTotalPrice,
	teamName, fieldTeam, userTeam, formationID, randomTeamIsLoading, isLoading,
	budgetLimit, nextRound, captainID, viceCaptainID, currentUserTeamFootballPlayersHashList, currentTeamName,
	createEditUserTeamRequestErrorAction, actionTeamsListRequest, actionPostRequestRandomTeam, actionResetTeam,
	actionInitTeam, createEditUserTeamPlayersRequestStartAction, actionSetCurrentTeamName, checkUserTeamRequestLoading, actionHeadCheckLiveSeasonUserTeamRequest
}) => {
	const { t }  = useTranslation("next_season_team");
	const [teamNameInputValue, setTeamNameInputValue] = useState("");
	const errorsRef = useRef(null);

	const query = useQuery();

	const footballFieldTop = useRef();

	const [playerListModalOpenState, setPlayerListModalOpenState] = useState(false);
	const [playerListModalHideState, setPlayerListModalHideState] = useState(false);
	const [previousErrorsLength, setPreviousErrorsLength] = useState(0);

	const [budgetLeft, setBudgetLeft] = useState(budgetLimit);

	const nextRoundDeadline = nextRound ? nextRound.userteam_deadline_without_year : "-";
	const nextRoundNumber = nextRound ? nextRound.number : "-";

	useLayoutEffect(() => {
		const incompleteTeamErrorMessage = query.get("incomplete_team")
			? t("Unfortunately there was trouble validating your team. Create or finalize your team before proceeding")
			: "";
		const brokenTeamErrorMessage = query.get("broken_team")
			? t("Team should be validated using \"Save Team\" button before proceeding")
			: "";
		const noTeamErrorMessage = query.get("no_team")
			? t("Team should be saved using \"Save Team\" button before proceeding")
			: "";
		const errorFromUrl = incompleteTeamErrorMessage || brokenTeamErrorMessage || noTeamErrorMessage;
		if (errorFromUrl) {
			createEditUserTeamRequestErrorAction({ parsedTextErrorsWithoutKeys: [errorFromUrl] });
		}
	},[]);

	useLayoutEffect(() => {
		if (userTeamsEditCreateRequestErrors && userTeamsEditCreateRequestErrors.length) {
			setPreviousErrorsLength(userTeamsEditCreateRequestErrors.length);
			if (previousErrorsLength <= userTeamsEditCreateRequestErrors.length) {
				scrollToErrors();
			}
		}
	}, [userTeamsEditCreateRequestErrors]);

	useEffect(() => {
		actionHeadCheckLiveSeasonUserTeamRequest(frontendUrls.urlNextRoundTeam);

		actionResetTeam({ withResetFormat: true });
	}, []);

	useEffect(() => {
		teamName && actionSetCurrentTeamName(teamName);
	}, [teamName]);

	useEffect(() => {
		if (teamNameInputValue && teamNameInputValue !== currentTeamName) {
			actionSetCurrentTeamName(teamNameInputValue);
		}
	}, [teamNameInputValue, currentTeamName]);

	useEffect(() => {
		if (userTeam && userTeam.name) {
			actionInitTeam(userTeam);
		}
	}, [userTeam]);


	useEffect(() => {
		const userErrorMessage = query.get("incomplete_team") || query.get("no_team") || query.get("broken_team");
		actionTeamsListRequest();
		!userErrorMessage && actionCleanUserTeamError();
	}, []);


	useEffect(() => {
		setBudgetLeft(budgetLimit - currentTotalPrice);
	}, [currentTotalPrice, budgetLimit]);


	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const userTeamValidate = () => {
		actionCleanUserTeamError();
		const errors = validateUserTeam({ fieldTeam, teamName: currentTeamName, captainID, viceCaptainID, currentTotalPrice });
		if (errors.length){
			createEditUserTeamRequestErrorAction({ parsedTextErrorsWithoutKeys: errors });
			scrollToErrors();
			return true;
		}
		else return false;
	};

	const handleCreateEditTeam = () => {
		const seasonID = leagueSeasons?.[0]?.id;
		actionCleanUserTeamError();
		const errors = validateUserTeam({ fieldTeam, teamName: currentTeamName, captainID, viceCaptainID, currentTotalPrice });
		if (!errors.length) {
			createEditUserTeamPlayersRequestStartAction({
				newFormationID: formationID,
				newTeam: fieldTeam,
				newCaptainID: captainID,
				newViceCaptainID: viceCaptainID,
				newTeamName: currentTeamName,
				currentTeam: userTeam,
				seasonID,
				nextRoundID: nextRound.id,
				currentUserTeamFootballPlayersHashList,
				isForNewSeason: true,
			});
		} else if (errors.length){
			createEditUserTeamRequestErrorAction({ parsedTextErrorsWithoutKeys: errors });
			scrollToErrors();
		}
	};

	const switchPlayersListModal = params => {
		if (typeof params === "string") {
			setPlayerListModalOpenState(params);
		} else {
			setPlayerListModalOpenState(!playerListModalOpenState);
		}
	};

	const switchPlayersListModalHide = () => {
		scrollToComponent(footballFieldTop.current, { offset: 180 });
		setPlayerListModalHideState(!playerListModalHideState);
	};

	const scrollToErrors = () => {
		if (errorsRef.current) {
			errorsRef.current.scrollIntoView(false);
			const scrollCorrection = isMobileResolution ? 20 : 30;
			window.scrollTo( 0,window.scrollY + scrollCorrection);
		}
	};

	const handleGenerateRandomTeam = () => {
		actionCleanUserTeamError();
		if (!formationID) {
			createEditUserTeamRequestErrorAction(
				{ parsedTextErrorsWithoutKeys: [t("Formation error. Please check your connection or contact us")] });
			scrollToErrors();
		} else {
			const football_players_data = [];
			Object.keys(fieldTeam).map(key => {
				if (key !== "bench") {
					fieldTeam[key].forEach(player => {
						if (player && player.id && !player.isRandomGenerated) {
							football_players_data.push({ bench_priority: null, football_player: player.id, position: player.position });
						}
					});
				} else {
					fieldTeam[key].forEach((player, index) => {
						if (player && player.id && !player.isRandomGenerated) {
							football_players_data.push({ bench_priority: index, football_player: player.id, position: player.position });
						}
					});
				}
			});

			if (football_players_data.length > 14) {
				football_players_data.length = 14;
			}

			actionPostRequestRandomTeam({
				formation: formationID,
				football_players_data,
			}).then((response) => {
				if (response && response.requestError) {
					scrollToErrors();
				}
			});
		}
	};

	const teamNameErrors = [
		TEAM_NAME_IS_REQUIRED,
		TEAM_NAME_BAD_WORDS,
		TEAM_NAME_ONLY_LATIN_OR_DIGITS,
		TEAM_NAME_SHOULD_BE_LESS_31
	];

	const activeTeamNameErrors = userTeamsEditCreateRequestErrors.filter((el => teamNameErrors.some(teamNameError => teamNameError === el)));
	return checkUserTeamRequestLoading ? null : (
		<div className="position-relative">
			<ProgressNextSeason seasonName={leagueSeasons?.[0]?.name || ""} />
			<Container className='select-team-container registration-field'>
				<Row>
					{!isMobileResolution &&
					<Col className='select-team-left-block col-3'>
						<PlayersListHeadStats
							budgetLeft={budgetLeft}
							fieldTeamLength={fieldTeamLength}/>
						<PlayersList/>
					</Col>
					}
					<Col className={`${isMobileResolution
						? "col-12 p-0"
						: ""} d-flex flex-column align-items-center`}>
						<Row
							className='select-team-top-controls'>
							<SpinnerButton
								text={t("Random Team")}
								isLoading={randomTeamIsLoading}
								onClick={handleGenerateRandomTeam}
								className="select-team-button double"
								size="medium"
								color="white"
							/>
							<span className='d-flex flex-column align-items-center justify-content-center'>
								<span
									className='deadline-text source-sans-pro color-secondary-text'>
									{t("Deadline before Round", { number: nextRoundNumber })}
								</span>
								<span className='mr-2 ml-2 bebas-neue font-size-36 color-black text-nowrap deadline-text-date'>
									{nextRoundDeadline}
								</span>
							</span>
							<MainButton
								handler={() => {actionResetTeam(); actionCleanUserTeamError();} }
								className="select-team-button double"
								text={t("Reset Team")}
								size="medium"
								color="white"
							/>
						</Row>
						{isMobileResolution &&
						<PlayersListHeadStats
							ref={footballFieldTop}
							budgetLeft={budgetLeft}
							fieldTeamLength={fieldTeamLength}/>
						}
						<FootballFieldContainer switchPlayersListModal={switchPlayersListModal} isSelectTeamPage dropCallback={switchPlayersListModal} dynamicField/>
						<Row className='select-team-bottom-controls container'>
							<Col xs={12} md={12} className="d-flex flex-column">
								<Input
									value={teamNameInputValue} 
									placeholder={t("Team Name")}
									className={`team-name-textfield ${activeTeamNameErrors.length && "with-error"}`}
									type="input" name="team-name"
									onChange={(e) => setTeamNameInputValue(e.target.value)}
									id="team-name"
								/>
								{activeTeamNameErrors.map((el, i) => (
									<ErrorMessage
										key={i}
										singleError={el}
										className="text-center"
									/>
								))}
							</Col>
						</Row>
						<Row className="errors-box w-100">
							<span ref={errorsRef} className='w-100 d-flex flex-column align-items-center'>
								{userTeamsEditCreateRequestErrors.filter((el => !teamNameErrors.some(teamNameError => teamNameError === el))).map((el, i) => (
									<Row
										key={i}
										className="team-error-next-round-wrapper"
									>
										<ErrorComponent
											handleDelete={actionCleanUserTeamError}
											text={el}
										/>
									</Row>
								))}
							</span>
						</Row>
					</Col>
				</Row>
			</Container>
			<Modal
				fade={false}
				modalClassName={`${playerListModalHideState ? "hidden-modal" : "modal-warning"}`}
				backdropClassName={`${playerListModalHideState ? "hidden-modal" : ""}`}
				className={`${playerListModalHideState ? "hidden-modal" : ""} modal-container-players-list`}
				contentClassName="modal-wrapper-players-list"
				isOpen={!!playerListModalOpenState}
				toggle={switchPlayersListModal}>
				<PlayersList
					openWithTab={playerListModalOpenState}
					switchPlayersListModalHide={switchPlayersListModalHide}
					isMobileResolution={isMobileResolution}
					setPlayerListModalHideState={setPlayerListModalHideState}
					setPlayerListModalOpenState={setPlayerListModalOpenState}
				/>
			</Modal>
			<PersonalDetailsShort isRegisterLoading={isLoading} validateUserTeam={userTeamValidate} handleCreateEditTeam={handleCreateEditTeam}/>
		</div>
	);
};

NextSeasonTeamContainer.propTypes = {
	currentUserEmail: PropTypes.string
};

const mapStateToProps = (state) => {
	const { budgetLimit } = state.settingsReducer;
	const {
		captainID,
		viceCaptainID,
		fieldTeam,
		formation: { id: formationID },
		randomTeamIsLoading,
		currentTeamName,
	} = state.footballFieldReducer;
	const {
		userTeamsData,
		createUserTeamLoading,
		userTeamPlayersRequestLoading,
		userTeamsEditCreateRequestErrors,
		checkUserTeamRequestLoading,
	} = state.userTeamsReducer;
	const userTeam = userTeamsData[0] || {};

	const isLoading = createUserTeamLoading || userTeamPlayersRequestLoading;
	const currentUserTeamFootballPlayersHashList = getUserTeamIdsPlayersHashList(state);
	const { id: teamID, name: teamName } = userTeam;
	const allRounds = getSortedRounds(state);
	const nextRoundWithoutModyfiedDate = getNextScheduledRound(state);
	const nextRound = nextRoundWithoutModyfiedDate ? allRounds.find(round => round.id === nextRoundWithoutModyfiedDate.id) : {};
	return {
		checkUserTeamRequestLoading,
		fieldTeamLength: getFieldPlayersTotalLength(state),
		currentTotalPrice: getFieldPlayersTotalPrice(state),
		nextRound,
		leagueSeasons: state.leagueSeasonsReducer.seasonsListData,
		teamID,
		teamName,
		userTeamsEditCreateRequestErrors,
		fieldTeam,
		userTeam,
		isLoading,
		fieldSnapshot: state.footballFieldReducer,
		formationID,
		randomTeamIsLoading,
		budgetLimit,
		captainID,
		viceCaptainID,
		currentUserTeamFootballPlayersHashList,
		currentTeamName,
	};
};

export default connect(
	mapStateToProps,
	{
		actionInitTeam,
		actionSetCurrentTeamName,
		createEditUserTeamPlayersRequestStartAction,
		createEditUserTeamRequestErrorAction,
		actionTeamsListRequest,
		actionResetTeam,
		actionPostRequestRandomTeam,
		actionCleanUserTeamError,
		actionHeadCheckLiveSeasonUserTeamRequest
	}
)(NextSeasonTeamContainer);

NextSeasonTeamContainer.propTypes = {
	errorsKeysMap: PropTypes.object.isRequired,
	userTeamsEditCreateRequestErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
	teamID: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	randomTeamIsLoading: PropTypes.bool.isRequired,
	fieldSnapshot: PropTypes.object.isRequired,
	actionCleanUserTeamError: PropTypes.func.isRequired,
	actionPostRequestRandomTeam: PropTypes.func.isRequired,
	leagueSeasons: PropTypes.arrayOf(PropTypes.shape(leaguesSeasonsPropType.leagueSeasons)).isRequired,
	createEditUserTeamPlayersRequestStartAction: PropTypes.func.isRequired,
	createEditUserTeamRequestErrorAction: PropTypes.func.isRequired,
	fieldTeamLength: PropTypes.number.isRequired,
	budgetLimit: PropTypes.number.isRequired,
	currentTotalPrice: PropTypes.number.isRequired,
	currentUserTeamFootballPlayersHashList: PropTypes.object.isRequired,
	nextRound: PropTypes.shape(footballRoundPropType.round).isRequired,
	fieldErrors: PropTypes.array.isRequired,
	teamName: PropTypes.string,
	currentTeamName: PropTypes.string,
	captainID: PropTypes.number,
	viceCaptainID: PropTypes.number,
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	userTeam: PropTypes.oneOfType([PropTypes.object, userTeamPropType]),
	isLoading: PropTypes.bool.isRequired,
	checkUserTeamRequestLoading: PropTypes.bool.isRequired,
	formationID: PropTypes.number,
	actionTeamsListRequest: PropTypes.func.isRequired,
	actionInitTeam: PropTypes.func.isRequired,
	actionResetTeam: PropTypes.func.isRequired,
	actionSetCurrentTeamName: PropTypes.func.isRequired,
	createEditUserTeamPlayersRequestErrorAction: PropTypes.func.isRequired,
	actionHeadCheckLiveSeasonUserTeamRequest: PropTypes.func.isRequired,
};

NextSeasonTeamContainer.defaultProps = {
	teamName: "",
	teamID: false,
	formationID: 0,
};

NextSeasonTeamContainer.displayName = "nstcon";