import React, { useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import frontendUrls from "routes/frontendUrls";

import { actionGetRequestUserTeams, actionHeadCheckLiveSeasonUserTeamRequest } from "redux/actions/userTeams";
import { actionSwitchSetNewPasswordModalOpenState } from "redux/actions/auth";
import { actionSetPlayerInfoModalID } from "redux/actions/playersList";
import { getIsChangedTeamStatus } from "redux/selectors";
import { actionClearSelectedRoundLeague } from "redux/actions/leaguesSelectedRoundLeague";

import useQuery from "helpers/hooks/useQuery";
import history from "helpers/history";
import useEffectSkipFirst from "helpers/hooks/useEffectSkipFirst";

import Announcement from 'modules/common/Announcement';
import SignInModal from "modules/auth/SignInModal";
import Header from "modules/header/components/Header";
import Home from "modules/home/containers/Home";
import Leagues from "modules/leagues/components/Leagues";
import LeaguesRanking from "modules/leagues/components/LeaguesRanking";
import ScheduleAndResults from "modules/scheduleAndResults/containers/ScheduleAndResults";
import News from "modules/news/containers/NewsAndSocial";
import Prizes from "modules/prizes/components/Prizes";
import RulesAndFaq from "modules/rulesAndFaq/components/RulesAndFaq";
import ContactUs from "modules/contactUs/containers/ContactUs";
import Main from "modules/main/components/Main";
import Registration from "modules/registration/containers/Registration";
import Footer from "modules/footer/components/Footer";
import ForgotPasswordModal from "modules/auth/ForgotPasswordModal";
import SetNewPasswordModal from "modules/auth/SetNewPasswordModal";
import NextRoundTeamContainer from "modules/nextRoundTeam/NextRoundTeamContainer";
import TransfersPageContainer from "modules/transfersPage/TransfersPageContainer";
import LivePreviousRoundTeamsContainer from "modules/livePreviousRoundTeams/LivePreviousRoundTeamsContainer";
import OtherUserTeamContainer from "modules/otherUserTeam/OtherUserTeamContainer";
import PersonalDetails from "modules/registration/containers/PersonalDetails";
import PlayerInfoModal from "modules/common/PlayerInfoModal/index";
import PrivateLeagueSettingsModal from "modules/common/PrivateLeagueSettingsModal";
import OwnPrivateLeagueSettingsModal from "modules/common/OwnPrivateLeagueSettingsModal";
import NotFoundPage from "modules/common/NotFoundPage";
import Maintenance from "modules/common/Maintenance";
import DownloadTheApp from "modules/common/DownloadTheApp";
import CheckAuthAndVerifiedRoute from "modules/hocs/checkAuthAndVerifiedRoute";
import NextSeasonTeamContainer from "modules/nextSeasonTeam/NextSeasonTeamContainer";


function Routes({
	isChangedTeamStatus,
	loginModalOpenState,
	forgotPasswordModalOpenState,
	setNewPasswordModalOpenState,
	playerInfoModalID,
	isUserLoggedIn,
	isUserEmailConfirmed,
	isUserTeamExist,
	activateAccountRequestLoading,
	userDetailsRequestLoading,
	isUserTeamExistStoreUpdateTime,
	actionHeadCheckLiveSeasonUserTeamRequest,
	actionClearSelectedRoundLeague,
	actionSwitchSetNewPasswordModalOpenState,
	actionSetPlayerInfoModalID,
	actionGetRequestUserTeams,
	userTeamsForFinishedSeasonsData,
	userTeamsForFinishedSeasonsDataStoreTimeUpdate,
	leavePrivateLeagueModalState,
	editPrivateLeagueModalState,
}) {
	const query = useQuery();
	let location = useLocation();

	useEffectSkipFirst(() => {
		if (isUserLoggedIn) {
			actionHeadCheckLiveSeasonUserTeamRequest(frontendUrls.urlMain);
		}
	}, [isUserLoggedIn, isUserEmailConfirmed]);

	useEffect(() => {
		if (isUserLoggedIn
			&& !isUserEmailConfirmed
			&& !activateAccountRequestLoading
			&& !userDetailsRequestLoading
			&&  location.pathname !== frontendUrls.urlConfirmEmail && location.pathname !== frontendUrls.urlActivationSuccess) {
			history.push(frontendUrls.urlConfirmEmail);
		}
	
		if (location.pathname === frontendUrls.urlRoot && isUserLoggedIn && isUserEmailConfirmed && !userDetailsRequestLoading) {
			history.push(frontendUrls.urlMain);
		}
	}, [location, isChangedTeamStatus, isUserEmailConfirmed]);

	useEffect(() => {
		if (isUserTeamExistStoreUpdateTime && !isUserTeamExist) {
			actionGetRequestUserTeams({ isForFinishedSeasons: true });
		}
	}, [isUserTeamExist, isUserTeamExistStoreUpdateTime]);

	const isFinishedSeasonsTeamExist = userTeamsForFinishedSeasonsData.length;

	useEffect(() => {
		if (isUserTeamExistStoreUpdateTime && userTeamsForFinishedSeasonsDataStoreTimeUpdate && !isUserTeamExist && isFinishedSeasonsTeamExist) {
			history.push(frontendUrls.urlNextSeasonTeam);
		}
	}, [isUserTeamExist, isUserTeamExistStoreUpdateTime, userTeamsForFinishedSeasonsData, userTeamsForFinishedSeasonsDataStoreTimeUpdate]);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);

		const isOtherUserTeamPage = window.location.pathname.includes(frontendUrls.urlOtherUserTeam);
		const isLeagueRankingPage = window.location.pathname.includes(frontendUrls.urlLeaguesRanking);
		const isLiveAndPreviousRoundPage = window.location.pathname.includes(frontendUrls.urlLivePreviousRoundTeams);

		if (!isOtherUserTeamPage && !isLeagueRankingPage && !isLiveAndPreviousRoundPage) {
			actionClearSelectedRoundLeague();
		}
	}, [location]);

	useEffectSkipFirst(() => {
		if (!isUserLoggedIn) {
			history.push("/");
		}
	}, [isUserLoggedIn]);

	return (
		<div className="d-flex flex-column global-root">
			{!!playerInfoModalID && <PlayerInfoModal switchModal={actionSetPlayerInfoModalID}/>}
			{!!loginModalOpenState && <SignInModal/>}
			{!!forgotPasswordModalOpenState && <ForgotPasswordModal/>}
			{!!setNewPasswordModalOpenState && <SetNewPasswordModal />}
			{!!leavePrivateLeagueModalState && <PrivateLeagueSettingsModal />}
			<OwnPrivateLeagueSettingsModal isOpen={!!editPrivateLeagueModalState} />
            <Announcement />
			<Header />
			<div className="content">
				<Switch>
					<Route exact path={frontendUrls.urlSetNewPassword}
						render={() => {
							if (!setNewPasswordModalOpenState) {
								const data = { uid: query.get("uid"), token: query.get("token") };
								actionSwitchSetNewPasswordModalOpenState(data);
							}
							return <Home />;}
						}/>
					<Route exact path={frontendUrls.urlRoot}>
						<Home />
					</Route>
					<CheckAuthAndVerifiedRoute
						component={Leagues}
						path={frontendUrls.urlLeagues}
					/>
					<CheckAuthAndVerifiedRoute
						component={LeaguesRanking}
						path={frontendUrls.urlLeaguesRanking}
					/>
					<Route path={frontendUrls.urlScheduleAndResults}>
						<ScheduleAndResults />
					</Route>
					<Route path={frontendUrls.urlNews}>
						<News />
					</Route>
					<Route path={frontendUrls.urlPrizes}>
						<Prizes />
					</Route>
					<Route path={frontendUrls.urlRulesAndFaq}>
						<RulesAndFaq />
					</Route>
					<Route path={frontendUrls.urlContactUs}>
						<ContactUs />
					</Route>
					<Route path={frontendUrls.urlSignUp}>
						<Registration />
					</Route>
					<Route path={frontendUrls.urlPersonalDetailsChange}>
						<PersonalDetails />
					</Route>
					<CheckAuthAndVerifiedRoute
						component={Main}
						path={frontendUrls.urlMain}
					/>
					<CheckAuthAndVerifiedRoute
						component={NextRoundTeamContainer}
						path={frontendUrls.urlNextRoundTeam}
					/>
					<CheckAuthAndVerifiedRoute
						component={TransfersPageContainer}
						path={frontendUrls.urlTransfers}
					/>
					<CheckAuthAndVerifiedRoute
						component={LivePreviousRoundTeamsContainer}
						path={frontendUrls.urlLivePreviousRoundTeams}
					/>
					<CheckAuthAndVerifiedRoute
						component={OtherUserTeamContainer}
						path={frontendUrls.urlOtherUserTeam}
					/>
					<CheckAuthAndVerifiedRoute
						component={NextSeasonTeamContainer}
						path={frontendUrls.urlNextSeasonTeam}
					/>
					<Route path={frontendUrls.urlMaintenance} >
						<Maintenance />
					</Route>
					<Route path={frontendUrls.urlDownloadTheApp} >
						<DownloadTheApp />
					</Route>
					<Route path="*" >
						<NotFoundPage />
					</Route>
				</Switch>
			</div>
			<Footer/>
		</div>
	);
}

const mapStateToProps = state => {
	const { authReducer, playersListReducer, userTeamsReducer, registerReducer, leaguesReducer } = state;
	const { userDetails, userDetailsRequestLoading, loginModalOpenState, forgotPasswordModalOpenState,
		setNewPasswordModalOpenState, isUserLoggedIn } = authReducer;
	const { is_email_verified: isUserEmailConfirmed } = userDetails;
	const { activateAccountRequestLoading } = registerReducer;
	const { playerInfoModalID } = playersListReducer;
	const { leavePrivateLeagueModalState, editPrivateLeagueModalState } = leaguesReducer;
	const isChangedTeamStatus = getIsChangedTeamStatus(state);
	const { isUserTeamExist, isUserTeamExistStoreUpdateTime, userTeamsForFinishedSeasonsData, userTeamsForFinishedSeasonsDataStoreTimeUpdate } = userTeamsReducer;

	return {
		leavePrivateLeagueModalState,
		editPrivateLeagueModalState,
		loginModalOpenState,
		forgotPasswordModalOpenState,
		setNewPasswordModalOpenState,
		playerInfoModalID,
		isChangedTeamStatus,
		isUserLoggedIn,
		isUserEmailConfirmed,
		activateAccountRequestLoading,
		userDetailsRequestLoading,
		isUserTeamExist,
		isUserTeamExistStoreUpdateTime,
		userTeamsForFinishedSeasonsData,
		userTeamsForFinishedSeasonsDataStoreTimeUpdate
	};
};

Routes.propTypes = {
	isChangedTeamStatus: PropTypes.bool.isRequired,
	loginModalOpenState: PropTypes.bool.isRequired,
	forgotPasswordModalOpenState: PropTypes.bool.isRequired,
	activateAccountRequestLoading: PropTypes.bool.isRequired,
	setNewPasswordModalOpenState: PropTypes.bool.isRequired,
	playerInfoModalID: PropTypes.number.isRequired,
	isUserLoggedIn: PropTypes.bool.isRequired,
	isUserEmailConfirmed: PropTypes.bool,
	userDetailsRequestLoading: PropTypes.bool.isRequired,
	checkUserTeamRequestLoading: PropTypes.bool.isRequired,
	isUserTeamExist: PropTypes.bool.isRequired,
	isUserTeamExistStoreUpdateTime: PropTypes.number.isRequired,
	actionSwitchSetNewPasswordModalOpenState: PropTypes.func.isRequired,
	actionGetRequestUserTeams: PropTypes.func.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
	actionHeadCheckLiveSeasonUserTeamRequest: PropTypes.func.isRequired,
	actionClearSelectedRoundLeague: PropTypes.func.isRequired,
	userTeamsForFinishedSeasonsData: PropTypes.array.isRequired,
	userTeamsForFinishedSeasonsDataStoreTimeUpdate: PropTypes.number.isRequired,
	leavePrivateLeagueModalState: PropTypes.oneOf(PropTypes.object, null).isRequired,
	editPrivateLeagueModalState: PropTypes.oneOf(PropTypes.object, null).isRequired,
};

export default connect(
	mapStateToProps,
	{ 
		actionGetRequestUserTeams,
		actionSwitchSetNewPasswordModalOpenState, 
		actionSetPlayerInfoModalID,
		actionHeadCheckLiveSeasonUserTeamRequest, 
		actionClearSelectedRoundLeague 
	},
)(Routes);

Routes.displayName = "rout";