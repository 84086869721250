import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType,
	leaguesSeasonsPropType
} from "utils/propTypes";
import { parsedFieldTeamType, userTeamPropType } from "utils/propTypes/footballTeam";

import { actionTeamsListRequest } from "redux/actions/teamsList";
import {
	createEditUserTeamPlayersRequestStartAction,
	actionCleanUserTeamError,
	createEditUserTeamRequestErrorAction,
} from "redux/actions/userTeams";
import {
	actionInitTeam,
} from "redux/actions/footballField";
import {
	getFieldPlayersTotalLength,
	getUserTeamIdsPlayersHashList,
	getNextScheduledRound,
} from "redux/selectors";

import { validateUserTeam } from "helpers/validateUserTeam";
import {
	TEAM_NAME_IS_REQUIRED,
	TEAM_NAME_ONLY_LATIN_OR_DIGITS,
	TEAM_NAME_BAD_WORDS,
	TEAM_NAME_SHOULD_BE_LESS_31
} from "helpers/constants/errors";

import FootballFieldContainer from "modules/footballField/containers/FootballFieldContainer";
import MainButton from "modules/common/MainButton";
import SpinnerButton from "modules/common/SpinnerButton";
import ErrorMessage from "modules/common/ErrorMessage";
import RoundResultsList from "modules/home/containers/RoundResultsList";
import ErrorComponent from "modules/common/ErrorComponent";
import SuccessComponent from "modules/common/SuccessComponent";
import RoundStatistic from "modules/common/RoundStatistic";
import LeaguesList from "modules/leagues/components/LeaguesList";

const NextRoundTeamContainer = ({
	userTeamsEditCreateRequestErrors, leagueSeasons, teamName, fieldTeam, userTeam, isLoading, formationID, nextRound,
	captainID, viceCaptainID, currentUserTeamFootballPlayersHashList, unchangedUserTeam,
	createEditUserTeamRequestErrorAction, createEditUserTeamPlayersRequestStartAction, actionTeamsListRequest,
	actionInitTeam, actionCleanUserTeamError }) => {
	const { t } = useTranslation("next_round_team");

	const errorsRef = useRef(null);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [teamNameLocal, setTeamNameLocal] = useState("");


	const [previousErrorsLength, setPreviousErrorsLength] = useState(0);

	const nextRoundDeadline = nextRound ? nextRound.userteam_deadline_without_year : "-";
	const nextRoundNumber = nextRound ? nextRound.number : "-";
	const nextRoundID = nextRound ? nextRound.id : 0;
	let location = useLocation();

	useEffect(() => {
		setShowSuccessMessage(false);
	}, [location.pathname]);


	useEffect(() => {
		teamName && setTeamNameLocal(teamName);
	}, [teamName]);

	useEffect(() => {
		if (userTeam && userTeam.name) {
			actionInitTeam(userTeam);
		}
	}, [userTeam]);

	useEffect(() => {
		actionTeamsListRequest();
		actionCleanUserTeamError();
	}, []);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const scrollToErrors = () => {
		if (errorsRef.current) {
			errorsRef.current.scrollIntoView(false);
			const scrollCorrection = isMobileResolution ? 20 : 30;
			window.scrollTo( 0,window.scrollY + scrollCorrection);
		}
	};

	const playerTypes = ["goalkeepers", "forwards", "defenders", "midfielders", "bench"];
	let wasTeamEdited = formationID !== unchangedUserTeam?.formation?.id ||	captainID !== unchangedUserTeam?.captainID || viceCaptainID !== unchangedUserTeam?.viceCaptainID ;
	if (!wasTeamEdited) {
		wasTeamEdited = playerTypes.some((playerType) => (
			fieldTeam?.[playerType].some((el, i) => el.id !== unchangedUserTeam?.fieldTeam?.[playerType]?.[i].id)
		));
	}

	const handleConfirmTeam = () => {
		if (wasTeamEdited) {
			const seasonID = leagueSeasons && leagueSeasons[0] && leagueSeasons[0].id;
			const errors = validateUserTeam({ fieldTeam, teamName: teamNameLocal, captainID, viceCaptainID });
	
			if (!errors.length && !isLoading) {
				createEditUserTeamPlayersRequestStartAction({
					newFormationID: formationID,
					newTeam: fieldTeam,
					newCaptainID: captainID,
					newViceCaptainID: viceCaptainID,
					newTeamName: teamNameLocal,
					currentTeam: userTeam,
					seasonID,
					nextRoundID,
					currentUserTeamFootballPlayersHashList,
					errorCallback: () => {setShowSuccessMessage(false);scrollToErrors();},
					successCallback: () => {setShowSuccessMessage(true);scrollToErrors();},
				});
			} else if (errors.length) {
				createEditUserTeamRequestErrorAction({ parsedTextErrorsWithoutKeys: errors });
			}
		}
	};

	useLayoutEffect(() => {
		if (userTeamsEditCreateRequestErrors && userTeamsEditCreateRequestErrors.length) {
			setPreviousErrorsLength(userTeamsEditCreateRequestErrors.length);
			if (previousErrorsLength <= userTeamsEditCreateRequestErrors.length) {
				scrollToErrors();
			}
		}
	}, [userTeamsEditCreateRequestErrors]);

	const teamNameErrors = [
		TEAM_NAME_IS_REQUIRED,
		TEAM_NAME_BAD_WORDS,
		TEAM_NAME_ONLY_LATIN_OR_DIGITS,
		TEAM_NAME_SHOULD_BE_LESS_31
	];

	return (
		<div className="position-relative">
			<Container className='select-team-container'>
				<Row>
					{!isMobileResolution &&
						<Col className='col-3 live-previous-left p-0 d-flex flex-column justify-content-start z-index-5'>
							<div>
								<RoundStatistic selectedRound={nextRound}/>
							</div>
							<LeaguesList typeLeagues="common" />
							<LeaguesList typeLeagues="private" />
						</Col>
					}
					<Col className={`${isMobileResolution
						? "col-12 p-0"
						: "col"} d-flex flex-column align-items-center`}>
						<Row
							className='select-team-top-controls'>
							<div className='select-team-button hidden-button'/>
							<span className='d-flex flex-column align-items-center justify-content-center'>
								<span
									className='deadline-text source-sans-pro color-secondary-text font-size-13'>
									{t("deadline_before", { number: nextRoundNumber })}
								</span>
								<span className='mr-2 ml-2 bebas-neue font-size-36 color-black text-nowrap deadline-text-date'>
									{nextRoundDeadline}
								</span>
							</span>
							<MainButton
								handler={() => {if (userTeam) {actionInitTeam(userTeam);} actionCleanUserTeamError();}}
								text={t("Reset Team")}
								size="medium"
								color="white"
								className="select-team-button single"
							/>
						</Row>
						<FootballFieldContainer isNextRoundPage selectedRoundId={nextRoundID} dynamicField withoutPlayerDeleteButton/>
						<Row className='select-team-bottom-controls mb container'>
							<Col xs={12} md={6} className="d-flex flex-column">
								<Row className='team-name-text'>
									{!!userTeam && userTeam.name}
								</Row>
								{userTeamsEditCreateRequestErrors.filter((el => teamNameErrors.some(teamNameError => teamNameError === el))).map((el, i) => (
									<ErrorMessage
										key={i}
										fieldName={el}
										errors={userTeamsEditCreateRequestErrors}
										className="text-center"
									/>))
								}
							</Col>
							<Col xs={12} md={6} className='select-team-bottom-controls-buttons d-flex flex-column'>
								<SpinnerButton
									isLoading={isLoading}
									text={t("Confirm team")}
									onClick={handleConfirmTeam}
									className="mw-auto text-uppercase single"
									size="large"
									disabled={!wasTeamEdited}
								/>
							</Col>
						</Row>
						<Row className="team-errors-box w-100">
							<span ref={errorsRef} className='w-100 d-flex flex-column align-items-center'>
								{showSuccessMessage && (
									<SuccessComponent
										handleDelete={() => {
											setShowSuccessMessage(false);
										}}
										className="team-error-wrapper"
										text={t("Team is successfully changed")}
									/>
								)}
								{userTeamsEditCreateRequestErrors.map((message, i) =>
									<Row
										key={i}
										className="team-error-wrapper"
									>
										<ErrorComponent
											handleDelete={actionCleanUserTeamError}
											text={message}
										/>
									</Row>
								)}
							</span>
						</Row>
						{isMobileResolution ?
							<Col className="live-previous-left p-0 z-index-5">
								<div>
									<RoundStatistic selectedRound={nextRound}/>
								</div>
								<LeaguesList typeLeagues="common" />
								<LeaguesList typeLeagues="private" />
							</Col> : null
						}
					</Col>
				</Row>
			</Container>
			<RoundResultsList onlyFutureRounds translation={{
				key: "round_results_title",
				ns: "next_round_team",
			}} />
		</div>
	);
};

NextRoundTeamContainer.propTypes = {
	currentUserEmail: PropTypes.string
};

const mapStateToProps = (state) => {
	const {
		fieldTeam,
		formation: { id: formationID },
		captainID,
		viceCaptainID,
		unchangedUserTeam,
	} = state.footballFieldReducer;
	const {
		userTeamsData,
		createUserTeamLoading,
		userTeamPlayersRequestLoading,
		userTeamsEditCreateRequestErrors,
		userTeamUpdateDataTime,
	} = state.userTeamsReducer;
	const userTeam = userTeamsData[0] || {};
	const currentUserTeamFootballPlayersHashList = getUserTeamIdsPlayersHashList(state);

	const isLoading = createUserTeamLoading || userTeamPlayersRequestLoading;
	const { name: teamName } = userTeam;

	return {
		fieldTeamLength: getFieldPlayersTotalLength(state),
		nextRound:  getNextScheduledRound(state),
		leagueSeasons: state.leagueSeasonsReducer.seasonsListData,
		teamName,
		userTeamsEditCreateRequestErrors,
		fieldTeam,
		userTeam,
		isLoading,
		formationID,
		captainID,
		viceCaptainID,
		currentUserTeamFootballPlayersHashList,
		userTeamUpdateDataTime,
		unchangedUserTeam,
	};
};

export default connect(
	mapStateToProps,
	{
		actionInitTeam,
		createEditUserTeamPlayersRequestStartAction,
		createEditUserTeamRequestErrorAction,
		actionTeamsListRequest,
		actionCleanUserTeamError,
	}
)(NextRoundTeamContainer);



NextRoundTeamContainer.propTypes = {
	errorsKeysMap: PropTypes.object.isRequired,
	userTeamsEditCreateRequestErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
	leagueSeasons: PropTypes.arrayOf(PropTypes.shape(leaguesSeasonsPropType.leagueSeasons)).isRequired,
	unchangedUserTeam: PropTypes.object.isRequired,
	createEditUserTeamPlayersRequestStartAction: PropTypes.func.isRequired,
	createEditUserTeamRequestErrorAction: PropTypes.func.isRequired,
	actionCleanUserTeamError: PropTypes.func.isRequired,
	fieldTeamLength: PropTypes.number.isRequired,
	userTeamUpdateDataTime: PropTypes.number.isRequired,
	currentUserTeamFootballPlayersHashList: PropTypes.object.isRequired,
	nextRound: PropTypes.shape(footballRoundPropType.round).isRequired,
	teamName: PropTypes.string,
	captainID: PropTypes.number.isRequired,
	viceCaptainID: PropTypes.number.isRequired,
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	userTeam: PropTypes.oneOfType([PropTypes.object, userTeamPropType]),
	isLoading: PropTypes.bool.isRequired,
	formationID: PropTypes.number,
	actionTeamsListRequest: PropTypes.func.isRequired,
	actionInitTeam: PropTypes.func.isRequired,
};

NextRoundTeamContainer.defaultProps = {
	teamName: "",
	formationID: 0,
};

NextRoundTeamContainer.displayName = "nrtcon";