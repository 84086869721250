/* eslint-disable max-len */
export default {
	"main_screen": {
		"Welcome to": "Benvenuto in",
		"Fantasy football for Italian top division.": "Fantacalcio nella massima divisione Italiana.",
		"At long last, Serie A gets the fantasy football game it deserves.": "Finalmente la Serie A ha il fantacalcio che si merita.",
		"Build your own 15-man team": "Costruisci la tua squadra di 15 giocatori",
		"Join common and private leagues": "Entra in leghe comuni e private",
		"Win great prizes": "Vinci grandi premi",
		"announcement_banner_text": "La nostra applicazione mobile è qui! <0>Scarica ora</0>"
	},
	"field_positions": {
		"goalkeeper": "portieri",
		"defender": "difensori",
		"midfielder": "centrocampisti",
		"forward": "attaccanti"
	},
	"action_types": {
		"GS_OP": "Gol segnati su azione",
		"GS_PK": "Gol segnati su rigore",
		"AS": "Assist",
		"PE": "Rigori guadagnati",
		"SoT": "Tiri in porta (ogni 2)",
		"CS_HT": "Clean sheets per ogni tempo di gioco",
		"TBI": "Contrasti / blocchi / intercettazioni (ogni 3)",
		"SA_OP": "Parate su azione (ogni 2)",
		"SA_PK": "Parate su rigore",
		"GC": "Gol subiti",
		"OG": "Autogol",
		"PM": "Rigori sbagliati",
		"YC": "Cartellini gialli",
		"RC": "Cartellini rossi"
	},
	"action_types_long": {
		"minutes_played": "Minuti giocati",
		"GS_OP": "Gol segnati su azione",
		"GS_PK": "Gol segnati su rigore",
		"AS": "Assist",
		"PE": "Rigori guadagnati",
		"SoT": "Tiri in porta (ogni 2)",
		"CS_HT": "Clean sheets per ogni tempo di gioco",
		"TBI": "Contrasti / blocchi / intercettazioni (ogni 3)",
		"SA_OP": "Parate su azione (ogni 2)",
		"SA_PK": "Parate su rigore",
		"GC": "Gol subiti",
		"OG": "Autogol",
		"PM": "Rigori sbagliati",
		"YC": "Cartellini gialli",
		"RC": "Cartellini rossi"
	},
	"action_types_short": {
		"MP": "MG",
		"GS_OP": "GS SA",
		"GS_PK": "GS SR",
		"AS": "AS",
		"PE": "RG",
		"SoT": "TiP",
		"CS_HT": "CS TG",
		"TBI": "CBI",
		"SA_OP": "PA SA",
		"SA_PK": "PA SR",
		"GC": "GS",
		"OG": "AG",
		"PM": "RS",
		"YC": "CG",
		"RC": "CR",
		"Round": "Giornata",
		"Contro": "Contro",
		"Points": "Punti",
		"Season": "Stagione",
	},
	"errors": {
		"TEAM_NAME_IS_REQUIRED": "Nome Squadra è necessario",
		"TEAM_NAME_BAD_WORDS": "Il nome della squadra non deve contenere parole inappropriate/offensive",
		"TEAM_NAME_ONLY_LATIN_OR_DIGITS": "Il nome della squadra deve contenere solo lettere latine o cifre",
		"TEAM_NAME_SHOULD_BE_LESS_31": "Il nome della squadra non può essere più lungo di 30 caratteri"
	},
	"validate_user_team": {
		"team_budget_is_overflown": "Il budget per la squadra è eccessivo",
		"max_players_from_one_club": "La squadra deve contenere al massimo {{playersFromOneClubLimit}} giocatori di un singolo club:",
		"team_should_have_a_captain_selected": "La squadra deve avere un capitano",
		"team_should_have_a_vice_captain_selected": "La squadra deve avere un vice capitano",
		"captain_and_vice_captain_can_t_be_the_same": "Il capitano e il vice capitano non possono essere gli stessi",
		"team_should_contain_15_players_for_all_positions_on_the_pitch": "La squadra deve essere composta da 15 giocatori per tutte le posizioni in campo"
	},
	"change_password_modal": {
		"title": "Cambia Password",
		"Old Password": "Vecchia password",
		"New Password": "Nuova password",
		"Confirm New Password": "Conferma la nuova password",
		"Save": "Salva",
		"Password": "Password"
	},
	"delete_account_modal": {
		"Confirm Account Deletion": "Conferma la cancellazione dell'Account",
		"All your personal details and all the other account related data will be permanently removed. Are you sure ?": "Tutti i vostri dati personali e tutti gli altri dati relativi all'account verranno rimossi in modo permanente. Siete sicuri ?",
		"confirm": "Conferma"
	},
	"forgot_password_modal": {
		"forgot password": "Password dimenticata",
		"Please enter your email for password reset": "Inserisci il tuo indirizzo email per reimpostare la password",
		"Email": "Email",
		"send": "Invia",
		"Email has been successfully sent": "L'email è stata inviata con successo",
		"Please check your email to reset your password": "Inserisci il tuo indirizzo email per reimpostare la password"
	},
	"set_new_password_modal": {
		"reset password": "Reimposta la password",
		"Please enter your new password": "Inserisci la nuova password",
		"New password": "Nuova password",
		"Confirm New Password": "Conferma la nuova password",
		"send": "Invia",
		"Password has been successfully changed": "La password è stata modificata con successo",
		"sign in": "Accedi"
	},
	"sign_in_modal": {
		"sign in": "Accedi",
		"Email or Password is invalid": "L'email o la password non sono valide",
		"Email": "Email",
		"Password": "Password",
		"Forgot your password ?": "Hai dimenticato la password ?"
	},
	"deadline": {
		"deadline": "Scadenza"
	},
	"game_info_modal": {
		"Match is scheduled": "La partita è programmata",
		"Match is cancelled": "La partita è cancellata",
		"Match is forfeited": "La partita è stata annullata",
		"Match data is being added": "I dati della partita verranno aggiunti"
	},
	"maintenance": {
		"Down for maintenance. Stay with us": "Inattivo per manutenzione. Rimani con noi"
	},
	"not_found_page": {
		"Page not found": "Pagina non trovata"
	},
	"own_private_league_settings_modal": {
		"Edit League": "Modifica lega",
		"League Code": "Codice della lega",
		"Close entries to the league": "Chiudi le iscrizioni alla lega",
		"Delete League": "Cancella la lega",
		"Save": "Salva",
		"Team successfully removed from this private league": "Squadra rimossa con successo da questa lega privata",
		"Enter team name to remove": "Inserisci il nome della squadra da rimuovere",
		"Loading...": "Caricamento...",
		"No teams found": "Nessuna squadra trovata",
		"Search and select the team to remove from this private league": "Cerca e seleziona la squadra da rimuovere da questa lega privata",
		"Remove team": "Rimuovi squadra",
	},
	"player_info_modal": {
	    "doubtful": "Dubbioso",
		"fit": "Forma",
		"international-duty": "Dovere internazionale",
		"minor-injury": "Infortunio minore",
		"serious-injury": "Infortunio grave",
		"suspended": "Sospeso",
		"transferred-out": "Trasferito",
		"unhealthy": "Indisposizione",
		"forward": "attaccanti",
		"midfielder": "centrocampisti",
		"defender": "difensori",
		"goalkeeper": "portieri",
		"Round": "Giornata",
		"total": "Totali",
		"score": "Punti",
		"selected by": "Selezionato dal {{percent}}%",
		"This player has not played any matches this season yet": "Questo giocatore non ha ancora giocato nessuna partita in questa stagione",
		"roundResults": "Risultati di giornata",
		"fixtures": "Calendario",
		"pastSeasonResults": "Risultati stagione",
		"Date": "Data",
		"Fixture": "Calendario",
		"command_vs_command": "<0>{{team1}}</0> vs <1>{{team2}}</1>",
	},
	"private_league_delete_modal": {
		"Confirm league removal": "Conferma la rimozione della lega",
		"This private league will be permanently removed. Are you sure ?": "Questa lega privata sarà rimossa definitivamente. Sei sicuro ?",
		"confirm": "Conferma"
	},
	"private_league_settings_modal": {
		"Confirm league exit": "Conferma l'uscita dalla lega",
		"Your team will be permanently removed from this Private League. Are you sure ?": "La tua squadra sarà definitivamente rimossa da questa Lega Privata. Sei sicuro ?",
		"confirm": "Conferma"
	},
	"round_statistic": {
		"round results": "<0>Risultati</0> Giornata {{roundNumber}}",
		"Points": "Punti",
		"transfers/deduction": "Trasferimenti <0>(Deduzione)</0>",
		"WC": "wC",
		"Round Average": "Media di Giornata",
		"Round Highest": "Massimo di Giornata"
	},
	"status": {
		"Live": "In diretta"
	},
	"total_statistic": {
		"season_results": "<0>Risultati</0> Stagione",
		"Total Points": "Punti Totali",
		"Total Transfers": "Trasferimenti Totali",
		"Season Average": "Media di Stagione",
		"Team Price": "Prezzo Squadra"
	},
	"contact_us": {
		"This field is required": "Questo campo è necessario",
		"First Name": "Nome",
		"Last Name": "Cognome",
		"Email": "Email",
		"What's this about": "Di cosa si tratta",
		"Message": "Messaggio",
		"Send": "Invia",
		"Company": "Sportsyte OU",
		"For marketing/sponsorship": "Per marketing/sponsorizzazioni",
		"Contact us": "Contattaci",
		"Email has been successfully sent": "L'email è stata inviata con successo",
		"Thank you for contacting us": "Grazie per averci contattato",
		"We will get in touch soon": "Ci metteremo presto in contatto con te",
		"contact_us": "Contattaci",
		"contact_details": "Dettagli",
		"Bug": "Bug",
		"Functionality change recommendation": "Suggerimenti di modifica della funzionalità",
		"New functionality recommendation": "Suggerimenti di nuove funzionalità",
		"Incorrect match entry": "Inserimento di una partita non corretta",
		"Other": "Altro"
	},
	"players_list": {
		"Points": "Punti",
		"points": "Punti",
		"Price": "Prezzo",
		"selected_by": "Selezionato dal",
		"minutes_played": "Minuti giocati",
		"goals_scored_from_open_play": "Gol segnati su azione",
		"goals_scored_from_penalty": "Gol segnati su rigore",
		"Assists": "Assist",
		"penalties_earned": "Rigori guadagnati",
		"shots_on_target": "Tiri in porta",
		"clean_sheets_for_every_half_time": "Clean sheets per ogni tempo di gioco",
		"Tackles/blocks/interceptions": "Contrasti / blocchi / intercettazioni",
		"saves_from_open_play": "Parate su azione",
		"saves_from_penalty": "Parate su rigore",
		"goals_conceded": "Gol subiti",
		"own_goals": "Autogol",
		"penalties_missed": "Rigori sbagliati",
		"yellow_cards": "Cartellini gialli",
		"red_cards": "Cartellini rossi",
		"Search": "Cerca",
		"Team": "Squadra",
		"Sort by": "Ordina per",
		"players": "giocatori",
		"budget": "budget",
		"transfers": "Trasferimenti",
		"deduction": "Deduzione",
		"FWD": "ATT",
		"MID": "CEN",
		"DEF": "DIF",
		"GKP": "POR"
	},
	"footer": {
		"Footer Text": "© Sportsyte OU 2024. Tutti i diritti riservati",
		"Privacy Policy": "Informativa sulla Privacy"
	},
	"header": {
		"Home": "Home",
		"Leagues": "Leghe",
		"Schedule": "Calendario",
		"News": "Notizie",
		"Prizes": "Premi",
		"Rules & FAQ": "Regole e FAQ",
		"Contact Us": "Contattaci",
		"Sign In": "Accedi",
		"Sign Up": "Iscriviti",
		"Announcement": "Scarica l'app",
	},
	"nav_item_dropdown": {
		"Next Round": "Giornata prossima",
		"Live & Previous Rounds": "Diretta e Giornata precedente",
		"Transfers": "Trasferimenti",
		"Team": "Squadra"
	},
	"profile_icon": {
		"Personal Details": "Dati personali",
		"Sign Out": "Esci"
	},
	"dream_team": {
		"dream_team": "<0>Squadra</0> dei Sogni"
	},
	"sign_in_up_buttons": {
		"Logout": "Esci",
		"Sign In": "Accedi",
		"Sign Up": "Iscriviti"
	},
	"sign_up_block": {
		"Create your own 15-man squad": "Costruisci la tua squadra di 15 giocatori",
		"Sign Up": "Iscriviti"
	},
	"tabs": {
		"Round": "Giornata",
		"Season": "Stagione"
	},
	"copy_icon": {
		"League Code copied to clipboard": "Codice della lega copiato negli appunti"
	},
	"leagues": {
		"create new league": "Crea lega privata",
		"join league": "Iscriviti lega privata",
		"leagues": "Leghe"
	},
	"leagues_list": {
		"common_header": "<0>Leghe</0> Comuni",
		"private_header": "<0>Leghe</0> Private",
		"members": "{{number}} membri",
		"Your team is not participating in Private Leagues.": "La sua squadra non partecipa alle Leghe Private.",
		"Create or join Private Leagues now": "Crea o unisciti alle leghe private ora"
	},
	"leagues_modal": {
		"join_title": "Iscriviti lega privata",
		"create_title": "Crea lega privata",
		"League Code": "Codice della lega",
		"League Name": "Nome della lega",
		"Point count starts from Round": "Punti inizierà dalla Giornata",
		"round": "Giornata {{number}}"
	},
	"leagues_ranking": {
		"Loading...": "Caricamento...",
		"select": "Seleziona {{item}}",
		"members": "{{count}} membri",
		"League Code": "Codice della lega",
		"All the members will appear in this league once the Round starts": "Tutti i membri appariranno in questa lega una volta che la Giornata avrà inizio",
		"Update": "Aggiornamento",
		"Count from": "Conteggio dalla",
		"round": "Giornata {{number}}",
		"title_private": "<0>Lega</0> Privata",
		"title_round": "<0>Lega</0> Giornata",
		"title_season": "<0>Lega</0> Stagione",
		"title_quad": "<0>Lega</0> Quad",
		"title_country": "<0>Lega</0> {{leagueName}}"
	},
	"members_list": {
		"place": "Posizione",
		"manager name": "Nome allenatore",
		"team name": "Nome Squadra",
		"points": "Punti"
	},
	"live_previous_round_teams": {
		"There are currently no live or finished Rounds in current season": "Attualmente non ci sono Giornate in diretta o concluse nella stagione in corso",
		"Previous round": "Giornata precedente",
		"Round": "Giornata",
		"Next round": "Giornata prossima",
		"Team not played": "La tua squadra non ha giocato in questa Giornata"
	},
	"news_and_rankings": {
		"news_and_rankings": "Notizie e <0>Classifiche</0>"
	},
	"welcome": {
		"Hello,": "Ciao,"
	},
	"news": {
		"Read more...": "Leggi di più..."
	},
	"news_and_article": {
		"news_and_numbers": "Notizie e <0>Numeri</0>"
	},
	"news_and_social": {
		"news_and_social": "Notizie e <0>Social</0>"
	},
	"numbers": {
		"Season users": "Utenti della stagione",
		"Country Leagues": "Leghe Nazionali",
		"Private Leagues": "Leghe Private"
	},
	"news_and_numbers": {
		"news_and_numbers": "Notizie e <0>Numeri</0>"
	},
	"next_round_team": {
		"deadline_before": "Scadenza Giornata {{number}}",
		"Reset Team": "Resetta Squadra",
		"Confirm team": "Conferma Squadra",
		"Team is successfully changed": "La squadra è stata modificata con successo",
		"round_results_title": "<0>Programma</0> di Giornata"
	},
	"next_season_team": {
		"Unfortunately there was trouble validating your team. Create or finalize your team before proceeding": "",
		"Team should be validated using \"Save Team\" button before proceeding": "",
		"Team should be saved using \"Save Team\" button before proceeding": "",
		"Formation error. Please check your connection or contact us": "",
		"Random Team": "Squadra casuale",
		"Deadline before Round": "Scadenza Giornata {{number}}",
		"Reset Team": "Resetta Squadra",
		"Team Name": "Nome Squadra"
	},
	"other_user_team": {
		"There are currently no live or finished Rounds in current season": "Attualmente non ci sono Giornate in diretta o concluse nella stagione in corso",
		"Previous round": "Giornata precedente",
		"Round": "Giornata",
		"Next round": "Giornata prossima",
		"Team not played": "Questa squadra non ha giocato in questa Giornata"
	},
	"place": {
		"first_place": "<0>1°</0> Posto",
		"second_place": "<0>2°</0> Posto",
		"third_place": "<0>3°</0> Posto"
	},
	"prizes": {
		"Prizes": "Premi"
	},
	"prizes_tabs": {
		"Round winner": "Vincitore della lega Giornata",
		"Quad round winner": "Vincitore della lega Quad",
		"Season winner": "Vincitore della lega Stagione"
	},
	"registration_completed": {
	    "email_verification_success": "Email verificata con <0>successo</0><1>Puoi continuare sul dispositivo corrente o sul dispositivo su cui hai avviato la verifica email</1>",
	    "email_verification_error": "Email verifica è <0>andata male</0><1>Per favore riprova o contattaci per segnalare il problema</1>",
	    "email_has_been_verified": "Email è già <0>stata verificata</0><1>Puoi continuare sul dispositivo corrente o sul dispositivo su cui hai avviato la verifica email</1>",
	    "email_verification_link_expired": "Email verifica link è <0>scaduto</0><1>Accedi per richiedere un nuovo link per la verifica dell'email</1>",
	    "Start Fantasy": "Inizia Fantacalcio",
	},
	"confirm_email": {
		"check_email": "L'email di conferma è stata inviata a <0>{{email}}.</0> Controlla la tua casella di posta per verificare la tua email.",
		"Havent received email ?": "Non hai ricevuto l'email ?",
		"Check your spam folder or click below to resend verification email": "Controlla la cartella spam o fai click qui sotto per inviare nuovamente l'email di verifica.",
		"resend email": "Nuovo l'email",
		"Email has been successfully sent": "L'email è stata inviata con successo"
	},
	"registration_form": {
		"changed_fields_success_message_one": "{{changedFieldsNames}} è stato modificato con successo",
		"changed_fields_success_message_other": "{{changedFieldsNames}} sono stati modificati con successo",
		"First Name": "Nome",
		"Last Name": "Cognome",
		"Team Name": "Nome Squadra",
		"Email": "Email",
		"Country": "Paese",
		"Password": "Password",
		"Change Password": "Cambia Password",
		"Confirm Password": "Conferma Password",
		"Delete Account": "Cancella Account",
		"agreement": "Seleziona qui per indicare che hai letto e accetti le <0>Condizioni d'Uso</0>",
		"save": "Salva",
		"register": "Registrazione",
		"You are enrolled to the league of selected country. Country cant be changed during season": "Sei iscritto alla lega del paese selezionato. Il paese non può essere cambiato durante la stagione",
		"You will be enrolled to the league of selected country. Country cant be changed during season": "Sarai iscritto alla lega del paese selezionato. Il paese non può essere cambiato durante la stagione"
	},
	"personal_details": {
		"personal_details": "Dati <0>Personali</0>",
		"Team Name": "Nome Squadra",
		"First Name": "Nome",
		"Last Name": "Cognome",
		"Country": "Paese",
		"save": "Salva",
		"You will be enrolled to the league of selected country. Country cant be changed during season": "Sarai iscritto alla lega del paese selezionato. Il paese non può essere cambiato durante la stagione"
	},
	"players_list_item": {
		"Added to the pitch": "Posizione in campo"
	},
	"progress_block": {
		"Select Team": "Seleziona Squadra",
		"Personal Details": "Dati Personali",
		"Confirm Email": "Conferma Email",
		"start fantasy": "Inizia Fantacalcio"
	},
	"progress_next_season": {
		"welcome": "Benvenuto nella stagione <0>{{seasonName}}</0>",
		"Select Team": "Seleziona Squadra",
		"Personal Details": "Dati personali"
	},
	"sign_up_closed": {
		"season": "2024-2025",
		"startTime": "Luglio",
		"sign_up_closed": "<0>Le iscrizioni</0> per la stagione <0>{{season}}</0> si apriranno a <0>{{startTime}}</0>"
	},
	"select_team": {
		"Unfortunately there was trouble validating your team. Create or finalize your team before proceeding": "",
		"Team should be validated using \"Save Team\" button before proceeding": "",
		"Team should be saved using \"Save Team\" button before proceeding": "",
		"Formation error. Please check your connection or contact us": "",
		"Random Team": "Squadra casuale",
		"Deadline before Round": "Scadenza Giornata {{round}}",
		"Reset Team": "Resetta Squadra",
		"Team Name": "Nome Squadra"
	},
	"rules_and_faq": {
		"rules_and_faq": "Regole e <0>FAQ</0>"
	},
	"rules_tab": {
		"Loading...": "Caricamento..."
	},
	"rules_and_faq_tabs": {
		"how to play": "Come giocare",
		"rules": "Regole",
		"FAQ": "FAQ"
	},
	"round_item": {
		"Round": "Giornata {{round}}",
		"completed": "Completato",
		"cancelled": "Annullato"
	},
	"schedule_and_results": {
		"schedule_and_results": "Calendario e <0>Risultati</0>"
	},
	"confirm_wildcard_modal": {
		"Confirm Wildcard": "Conferma Wildcard",
		"Confirmation Text": "La squadra avrà a disposizione un numero illimitato di trasferimenti prima della prossima Giornata. Una volta attivata, la Wildcard non può essere annullata.",
		"Confirm": "Conferma"
	},
	"transfers_page": {
		"Wildcard has already been used this season": "La Wildcard è già stata utilizzata in questa stagione",
		"Wildcard": "Wildcard",
		"Deadline": "Scadenza Giornata {{round}}",
		"Reset Team": "Resetta Squadra",
		"Confirm transfers": "Conferma Trasferimento",
		"Transfers are successfully completed": "I trasferimenti sono stati completati con successo",
		"confirm transfers": "Conferma Trasferimento",
		"Transfers Out": "Trasferimenti in uscita",
		"Transfers In": "Trasferimenti in entrata",
		"confirm": "Conferma",
		"round_results_title": "<0>Programma</0> di Giornata"
	},
	"auth_errors": {
		"email_is_required": "Email è necessario",
		"email_should_have_correct_format_email_example_com": "L'email deve avere un formato corretto, email@example.com",
		"password_is_required": "Password è necessario",
		"please_use_correct_email_address": "Si prega di utilizzare l'indirizzo email corretto"
	},
	"dream_team_errors": {
		"no_season_team": "Nessuna Squadra dei sogni per la Stagione: {{season}}",
		"no_round_team": "Nessuna Squadra dei sogni per l'ultima Giornata: {{round}}",
		"invalid_id": "ID Giornata non valido"
	},
	"private_leagues_errors": {
		"league_code_is_required": "Il codice della lega è necessario"
	},
	"network_errors": {
		"server_error": "itServer error",		
		"error_500": "Errore 500",
		"status": "Stato: {{status}}",
		"code": "Codice: {{code}}",
		"wrong_request_url": "URL richiesto errato"
	},
	"validation_errors": {
		"email_should_have_correct_format_email_example_com": "L'email deve avere un formato corretto, email@example.com",
		"password_requirements": "La password deve contenere almeno 8 caratteri, 1 numero, 1 minuscola e 1 lettera maiuscola",
		"password_mismatch": "La password non corrisponde",
		"should_contain_only_latin_letters": "{{field}} deve contenere solo lettere latine",
		"should_not_contain_inappropriate_offensive_words": "{{field}} non deve contenere parole inappropriate/offensive",
		"can_t_be_longer_than_30_characters": "{{field}} non può essere più lungo di 30 caratteri",
		"first_name": "Nome",
		"is_required": "{{field}} è necessario",
		"last_name": "Cognome",
		"email": "Email",
		"country": "Paese",
		"password": "Password",
		"confirm_password": "Conferma Password",
		"team_name": "Nome Squadra",
		"old_password": "Vecchia password",
		"league_name_can_t_be_longer_than_30_characters": "Nome del lega non può essere più lungo di 30 caratteri",
		"league_name": "Nome della lega",
		"this_field": "Questo campo",
		"league_code": "Codice della lega"
	},
	"switch": {
		"switchRound": "Giornata <0>{{value}}</0>",
		"switchSeason": "Stagione <0>{{value}}</0>"
	},
	"round_results": {
		"title": "<0>Risultati</0> di Giornata"
	},
	"field_player_item": {
		"FWD": "ATT",
		"MID": "CEN",
		"DEF": "DIF",
		"GKP": "POR"
	}
};